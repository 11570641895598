// other Questionmarks text
export const showCommentsQ = [
    `We will gather the specified types of comments for you under 'View history.' If you select them, our system will automatically detect and ensure these types of comments are not missed. For example, with inquiry comments, you can have a page dedicated to comments that ask questions. For business comments, you will easily see what kinds of collaboration or business opportunities have been suggested. For praise comments, if you ever want to read only encouraging words, you'll know exactly where to find them.`,
    `We will gather the specified types of comments for you under 'View history.' If you select them, our system will automatically detect and ensure these types of comments are not missed. For example, with inquiry comments, you can have a page dedicated to comments that ask questions. For business comments, you will easily see what kinds of collaboration or business opportunities have been suggested. For praise comments, if you ever want to read only encouraging words, you'll know exactly where to find them.`,
    `We will gather the specified types of comments for you under 'View history.' If you select them, our system will automatically detect and ensure these types of comments are not missed. For example, with inquiry comments, you can have a page dedicated to comments that ask questions. For business comments, you will easily see what kinds of collaboration or business opportunities have been suggested. For praise comments, if you ever want to read only encouraging words, you'll know exactly where to find them.`,

]
export const deleteCommentsQ = [
    `Select the types of comments you want us to delete. For instance, if you wish to remove all negative feedback, such as comments like 'Oh, I think this YouTuber doesn't know what he/she is talking about,' simply click on this option. You can also choose to delete other types of comments by clicking on the corresponding options.`,
    `Select the types of comments you want us to delete. For instance, if you wish to remove all negative feedback, such as comments like 'Oh, I think this YouTuber doesn't know what he/she is talking about,' simply click on this option. You can also choose to delete other types of comments by clicking on the corresponding options.`,
    `Select the types of comments you want us to delete. For instance, if you wish to remove all negative feedback, such as comments like 'Oh, I think this YouTuber doesn't know what he/she is talking about,' simply click on this option. You can also choose to delete other types of comments by clicking on the corresponding options.`,

]
export const blockUserQ = [
    `Instead of deleting comments, some YouTubers prefer to hide users. This is useful when you suspect that certain users will return to check if their comments have been deleted. Occasionally, these users might post additional comments like 'Why were my comments deleted?' which other viewers may see before we filter them out, if you wish. When a user is blocked, it means they can still see their own comments on the video, but no one else, aside from them, will be able to view those comments.`,
    `Instead of deleting comments, some YouTubers prefer to hide users. This is useful when you suspect that certain users will return to check if their comments have been deleted. Occasionally, these users might post additional comments like 'Why were my comments deleted?' which other viewers may see before we filter them out, if you wish. When a user is blocked, it means they can still see their own comments on the video, but no one else, aside from them, will be able to view those comments.`,
    `Instead of deleting comments, some YouTubers prefer to hide users. This is useful when you suspect that certain users will return to check if their comments have been deleted. Occasionally, these users might post additional comments like 'Why were my comments deleted?' which other viewers may see before we filter them out, if you wish. When a user is blocked, it means they can still see their own comments on the video, but no one else, aside from them, will be able to view those comments.`,
]

export const objectQ = {
    customShowQ: ` Besides the types of comments mentioned above, if you have any specific types of comments you'd like us to collect, we're here to work with you. Simply type what you need. You can provide more details in the box and save your preferences using the button below. This feature is currently available exclusively for the 'Celebrity plan.'`,
    customDeleteQ: `Besides the types of comments specified above for deletion, if you wish for us to screen specific types of comments tailored to your needs, simply describe these comments in this box and click the save button below. Feel free to provide a detailed description in the box, so we can better understand your requirements.`,
    customBolckQ: `Similar to the customization for deleting comments, if you need specific types of comments to be hidden from other users, please describe them in the box and click the save button. You can provide a more detailed description if you deem it necessary.`,
    additionRechargeQ: "Addition Recharge"
}