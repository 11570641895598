import React from 'react'
import Header from '../components/Header'
import Aboutus from '../components/Aboutus'

function About() {
    return (
        <>
            <Header />
            <div id="about" className="about-us section" style={{ paddingTop: "0" }}>
                <Aboutus />
            </div>
        </>

    )
}

export default About
