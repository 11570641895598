import React from 'react'
import '../../styles/loading.css'

const Loading = () => {
    return (

        <div className='loading-loader'>
            <div className="loading-dotted">
                <li ></li>
                <li ></li>
                <li ></li>
            </div>
        </div>


    )
}

export default Loading


//#ECF0F3
// #E3E6EC