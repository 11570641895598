import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get_all_report_of_user } from '../redux/actions/user/ReportAction'
import { Audio } from 'react-loader-spinner'
import { useTranslation } from 'react-i18next'



function HistoryTable() {
    const { t } = useTranslation();
    const token = localStorage.getItem('eonToken')
    const channelId = localStorage.getItem('selectedChannelId')
    const channelName = localStorage.getItem('selectedChannel')
    const dispatch = useDispatch()
    const statistics = useSelector(state => state.report.statistics)
    const statisticsLoading = useSelector(state => state.report.loading)
    let currentFeatureTab = localStorage.getItem("featureName")
    currentFeatureTab = currentFeatureTab ? currentFeatureTab : 'Show Comments'
    const [selectedTab, setSelectedTab] = useState(currentFeatureTab);

    useEffect(() => {
        window.scrollTo(0, 0);
        // Update localStorage when selectedTab changes
        localStorage.setItem('featureName', selectedTab);
    }, [selectedTab]);



    useEffect(() => {
        if (token) {
            dispatch(get_all_report_of_user(token, channelId))
        }
    }, [dispatch, token, channelId])


    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50; // Number of items to display per page

    // Calculate the range of data to display based on current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Handle "Previous" button click
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Handle "Next" button click
    const handleNextPage = () => {
        // Assuming statistics is an array containing all data
        if (endIndex < statistics.length) {
            setCurrentPage(currentPage + 1);
        }
    };


    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    const tabDescriptions = {
        'Show Comments': 'Show Comments',
        'Deleted Comments': 'Deleted Comments',
        'Blocked User': 'Blocked User',
    };

    // Display only the data for the current page
    const displayedData = statistics?.slice(startIndex, endIndex);

    return (

        <div className='bg-gray-950'>
            <div className=" mx-auto ">
                <div className="overflow-x-auto">
                    <h2 className="my-[50px] text-center font-bold text-[white] sm:text-[25px] md:text-[25px]">
                        {t('Consultation Sessions')}
                    </h2>
                    <div>
                        <div className="grid grid-cols-3 gap-[20px] px-[20px]">
                            <button
                                className={` p-[20px] rounded ${selectedTab === 'Show Comments' ? 'bg-gray-300 text-black' : 'bg-gray-800 text-white'} font-bold text-[18px] shadow-md flex items-center justify-center`}
                                onClick={() => handleTabClick('Show Comments')}
                            >
                                {t('Show Comments')}
                            </button>
                            <button
                                className={`p-[20px] rounded ${selectedTab === 'Deleted Comments' ? 'bg-gray-300 text-black' : 'bg-gray-800 text-white'} font-bold text-[18px] text-indigo-500 shadow-md flex items-center justify-center`}
                                onClick={() => handleTabClick('Deleted Comments')}
                            >
                                {t('Deleted Comments')}
                            </button>
                            <button
                                className={`p-[20px] rounded ${selectedTab === 'Blocked User' ? 'bg-gray-300 text-black' : 'bg-gray-800 text-white'} font-bold text-[18px] text-indigo-500 shadow-md flex items-center justify-center`}
                                onClick={() => handleTabClick('Blocked User')}
                            >
                                {t('Blocked User')}
                            </button>
                        </div>
                        <div className="mt-4 px-[20px]">
                            {selectedTab && (
                                <div
                                    className="shadow-xl  font-semibold p-8 rounded-[8px]  bg-gray-800 mt-6 "
                                >
                                    <table className="min-w-full bg-gray-950  border-gray-700 border-b-2">
                                        <thead className="bg-gray-800  font-bold">
                                            <tr className='className="bg-gray-900 text-white uppercase text-[14px] leading-normal'>
                                                <th className="px-6 py-4 text-left  uppercase tracking-wider">{t('Channel Name')}</th>
                                                <th className="px-6 py-4 text-left  uppercase tracking-wider">{t('Analyze Name')}</th>
                                                <th className="px-6 py-4 text-left  uppercase tracking-wider">{t('Last Analyze Time')}</th>
                                            </tr>
                                        </thead>
                                        {token && statistics && !statisticsLoading &&
                                            <tbody className="divide-y bg-gray-900  divide-gray-700 text-white">

                                                {displayedData?.map((data, index) => (
                                                    <tr
                                                        key={data._id}
                                                        className={index % 2 === 0 ? "bg-gray-600 text-gray-200" : "bg-gray-700 text-gray-200"}
                                                    >
                                                        <td className="px-6 py-4 md:whitespace-nowrap">{channelName}</td>
                                                        <td className="px-6 py-4 md:whitespace-nowrap">{data.analyze}</td>
                                                        <td className="px-6 py-4 md:whitespace-nowrap">
                                                            {data.statistics.viewCount}
                                                        </td>
                                                        <td className="px-6 py-4 md:whitespace-nowrap">
                                                            {data.statistics.subscriberCount}
                                                        </td>
                                                        <td className="px-6 py-4 md:whitespace-nowrap">
                                                            {data.statistics.videoCount}
                                                        </td>
                                                        <td className="px-6 py-4 md:whitespace-nowrap">
                                                            {data.last_report_date}
                                                        </td>
                                                    </tr>
                                                ))
                                                }
                                            </tbody>
                                        }
                                    </table>
                                    {token && statistics.length > 0 && !statisticsLoading &&
                                        <div className="flex justify-center items-center h-[15vh] ">
                                            <div className="sm:flex sm:items-center sm:justify-between text-center w-full px-[30px]">
                                                <div className="text-sm text-gray-500 dark:text-gray-400">
                                                    Page <span className="font-medium text-gray-700 dark:text-gray-100">{currentPage} of {Math.ceil(statistics.length / itemsPerPage)}</span>
                                                </div>

                                                <div className="flex items-center gap-x-4 sm:mt-0">
                                                    <button
                                                        onClick={handlePreviousPage}
                                                        disabled={currentPage === 1}
                                                        className={`flex font-bold items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-100 capitalize transition-colors duration-200 bg-gray-900 rounded-md sm:w-auto gap-x-2 hover:text-red-500 ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''}`}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                                                        </svg>
                                                        <span>Previous</span>
                                                    </button>
                                                    <button
                                                        onClick={handleNextPage}
                                                        disabled={endIndex >= statistics.length}
                                                        className={`flex font-bold items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-100 capitalize transition-colors duration-200 bg-gray-900 rounded-md sm:w-auto gap-x-2 hover:text-red-500 ${endIndex >= statistics.length ? 'cursor-not-allowed opacity-50' : ''}`}
                                                    >
                                                        <span>Next</span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {token && statisticsLoading && (
                                        <div className="flex justify-center items-center h-[70vh]">
                                            <div className='text-center'>
                                                <Audio
                                                    height="80"
                                                    width="80"
                                                    radius="9"
                                                    color="red"
                                                    ariaLabel="loading"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {statistics.length === 0 && !statisticsLoading &&
                                        < div className="flex justify-center items-center h-[70vh] w-[100%]">
                                            <div className='text-center text-white text-2xl'>
                                                {t('No Data found for')} {t(tabDescriptions[selectedTab])}
                                            </div>
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div >
        </div >

    )
}

export default HistoryTable
