import React from 'react'
import { GLOBALTYPES } from '../../redux/actions/GlobalTypes';
import { useDispatch } from 'react-redux';
import UnsubscribePopUp from './UnsbscribePopUp';

function UnSubscribe() {
    const dispatch = useDispatch();

    const handleCloseModal = () => {
        dispatch({
            type: GLOBALTYPES.UNSUBSCRIBE,
            payload: {
                unsubscribe: false
            }
        })
    }

    return (
        <UnsubscribePopUp display={true} onClose={handleCloseModal} />
    )
}

export default UnSubscribe
