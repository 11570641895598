import React from 'react'

function VideosSection() {
    return (
        <div className='max-w-[1450px] mx-auto mt-[50px] bg-gray-900 rounded-[10px] py-[50px] px-[20px]'>
            <div className="container   ">
                <section className="">


                    <div className="mb-16 flex flex-wrap">
                        <div className="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pr-6">
                            <div
                                className="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
                                data-te-ripple-init data-te-ripple-color="light">
                                <img src="assets/images/broken1.png" className="w-full" alt="Louvre" />
                                <a href="#!">
                                    <div
                                        className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6">
                            <div className='video-section-round-div flex items-center justify-center'>
                                <span className="text-white text-[18px] font-bold">01</span>
                            </div>
                            <h3 className="my-4 text-2xl text-white font-bold">'Broken Window' theory?</h3>
                            <p className="mb-6 text-neutral-500 dark:text-neutral-300">
                                Have you heard of the broken window theory? It says that if there's a broken window in a house, other people might think it's okay to break in and cause even more damage. Just like that, if you leave bad comments on your YouTube channel, other people might also think it's okay to leave nasty comments, tainting your channel even more.
                            </p>

                        </div>
                    </div>

                    <div className="mb-16 flex flex-wrap lg:flex-row-reverse">
                        <div className="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pl-6">
                            <div
                                className="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
                                data-te-ripple-init data-te-ripple-color="light">
                                <img src="assets/images/broken2.png" className="w-full" alt="Louvre" />
                                <a href="#!">
                                    <div
                                        className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pr-6">
                            <div className='video-section-round-div-2 flex items-center justify-center'>
                                <span className="text-white text-[18px] font-bold">02</span>
                            </div>
                            <h3 className="my-4 text-2xl text-white font-bold">We delete bad comments for you</h3>
                            <p className="mb-6 text-neutral-500 dark:text-neutral-300">
                                Don’t just assume it's okay for people to leave bad comments. Sometimes, it’s important to point out what’s unacceptable, especially since this is your space – your house, your rules. If you don’t like certain comments, feel free to rule them out.
                            </p>
                        </div>
                    </div>

                    <div className="flex flex-wrap">
                        <div className="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pr-6">
                            <div
                                className="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
                                data-te-ripple-init data-te-ripple-color="light">
                                <img src="assets/images/broken3.png" className="w-full" alt="Louvre" />
                                <a href="#!">
                                    <div
                                        className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6">
                            <div className='video-section-round-div-3 flex items-center justify-center'>
                                <span className="text-white text-[18px] font-bold">03</span>
                            </div>
                            <h3 className="my-4 text-2xl text-white font-bold">Don't let them destroy your house!</h3>
                            <p className="mb-6 text-neutral-500 dark:text-neutral-300">
                                Now, you can have peace of mind knowing that every time you look at the comment section, it will be a positive and constructive community. This will encourage you to create more content and let your creativity soar! You'll have all the constructive feedback, while filtering out all the intentionally negative comments that help nobody.
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default VideosSection
