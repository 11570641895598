import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { GLOBALTYPES } from '../redux/actions/GlobalTypes';
import { getDataAPI } from '../utils/FetchData';
import { plansDefault } from '../common/plan';
import { getActivePlan } from '../redux/actions/user/Subscription';
import { BsFillCheckCircleFill, BsQuestionLg, BsXCircleFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next'
import { Popover, ButtonToolbar, OverlayTrigger, Button } from 'react-bootstrap'
import { objectQ } from '../common/questionMark';



function Price({ header }) {
    const { t } = useTranslation();
    const [plans, setPlans] = useState(plansDefault)
    const token = localStorage.getItem('eonToken')
    const channelId = localStorage.getItem('selectedChannelId')
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const activePlan = useSelector(state => state.subscription?.subscribed_plan)
    const channelIdRedux = useSelector(state => state.youtube?.channelId)
    const isYoutubeSignIn = localStorage.getItem('isYoutubeSignIn')

    const handlePurchase = (price, planType, planDuration, balanceUpdater, inde, channelId, credits) => {

        if (!(activePlan?.index + 1)) {

            if (isYoutubeSignIn) {

                navigate('/payment', { state: { price: price, planType: planType, planDuration: planDuration, balanceUpdater: balanceUpdater, index: inde, channelId: channelId, credits: credits } });
            }
            else {
                dispatch({
                    type: GLOBALTYPES.ALERT,
                    payload: {
                        error: 'First, Sign In with Youtube.'
                    }
                })
            }
        }
        else {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: 'Already Onther Plan is Activated'
                }
            })
        }
    };

    const handleAdditionPurchase = (price, planId, channelId, inde, credits) => {
        if (activePlan?.index === inde) {
            navigate('/additionalrecharge', {
                state: { price: price, planId: planId, channelId: channelId, index: inde, credits: credits }
            })
        }
        else {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: 'Please Subscribe The Plan'
                }
            })
        }
    }

    const handleSignIn = () => {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {

            }
        })
        dispatch({
            type: GLOBALTYPES.SIGNIN,
            payload: {
                signin: true
            }
        })
    }

    const handleUnSubscribe = () => {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {

            }
        })
        dispatch({
            type: GLOBALTYPES.UNSUBSCRIBE,
            payload: {
                unsubscribe: true
            }
        })
    }

    useEffect(() => {
        if (token && channelId) {
            dispatch(getActivePlan(token, channelId))
        }
    }, [dispatch, token, channelIdRedux, channelId])


    useEffect(() => {
        async function getPlan() {
            try {
                const res = await getDataAPI(`user/make_plan`)
                if (res) {
                    setPlans(res?.data?.data?.plans)
                }
            } catch (error) {
                console.log('error', error)
            }
        }
        getPlan()
    }, [dispatch, token])

    return (
        <div className="max-w-[1450px] mx-auto" style={{ marginTop: !header ? "50px" : "0px" }}>
            <div className="row bg-gray-900 py-[40px] px-[15px] rounded-[10px]">
                {header ? (
                    <div className="col-lg-8 offset-lg-2">
                        <div className="section-heading text-[25px]">
                            <h4>{t('Step 2')} : {t('Select Your')} <em >{t('Subscription')}</em></h4>
                        </div>
                    </div>
                ) : null}
                {plans?.map((plan, inde) => (
                    <div key={inde} className='col-xxl-3 col-lg-4  col-sm-6 col-xs-12'>
                        <div
                            key={plan?.planType}
                            className=
                            {activePlan?.index === inde ? `pricing-item-regular py-[30px] bg-gray-900 rounded-[30px] border-[2px] border-solid border-gray-700 px-[30px]`
                                : `pricing-item-regular py-[30px] px-[30px] bg-gray-800 rounded-[30px] border-[2px] border-solid border-gray-700  ${activePlan?._id ? 'not-selected-plan-margin' : ''}`
                            }
                            style={{
                                boxShadow: "0px 10px 20px 5px rgb(0, 0, 0, 0.6)"

                            }}
                        >
                            <div className=" flex  flex-col ">
                                {inde === 0 ?
                                    <>
                                        <div className='p-[0px] h-full '>
                                            {activePlan?.index === inde && <div className='flex justify-end'>
                                                <div className='px-[15px] border-solid border-yellow-500 border-[1px] bg-yellow-200 py-[3px] rounded-[20px] font-bold  text-[12px]' style={{ boxShadow: "0px 2px 4px rgba(25, 25, 25, 0.4), 0px 7px 13px -3px rgba(25, 25, 25, 0.3), inset 0px -3px 0px rgba(25, 25, 25, 0.2)" }} >{t('Current')}</div>
                                            </div>
                                            }

                                            <header className="flex gap-[10px] flex-col items-start  mb-[10px]">

                                                <h3 className=" text-yellow-300  font-semibold text-[28px]">{plan.planType}</h3>

                                            </header>
                                            <div className="font-bold text-yellow-200 ">
                                                <div className="h-[100px] flex items-center  gap-[1.5px]">
                                                    <div className="text-[28px] mb-[15px]">$</div>
                                                    <div className='text-[60px] text-white'>{plan.price}</div>
                                                    <div className='items-start flex flex-col'>
                                                        <div className='text-[16px] line-through  text-white'>${plan?.WithoutDiscountPrice}</div>
                                                        <div className='text-[16px] mt-[16px]'>/month</div>
                                                    </div>
                                                </div>


                                            </div>

                                            {activePlan?.index === inde
                                                ?
                                                <>
                                                    <button className='w-full mt-[10px] border-solid border-gray-500 border-[1px] bg-gray-200 py-[10px] rounded-[35px] font-bold  text-[17px]' style={{ boxShadow: "0px 2px 4px rgba(25, 25, 25, 0.4), 0px 7px 13px -3px rgba(25, 25, 25, 0.3), inset 0px -3px 0px rgba(25, 25, 25, 0.2)" }} onClick={() => token ? handleUnSubscribe() : handleSignIn()}>{t('UnSubscribe')}</button>

                                                    <button className='relative w-full mt-[15px] border-solid border-yellow-500 border-[1px] bg-yellow-300 py-[10px] rounded-[35px] font-bold  text-[17px]' style={{ boxShadow: "0px 2px 4px rgba(25, 25, 25, 0.4), 0px 7px 13px -3px rgba(25, 25, 25, 0.3), inset 0px -3px 0px rgba(25, 25, 25, 0.2)" }} onClick={() => token ? handleAdditionPurchase(plan.price, activePlan.planId, channelId, inde, plan.credits) : handleSignIn()}>
                                                        <span>{t('Additional Recharge')}</span>
                                                        <div className='absolute top-0 bottom-0 right-[10px] flex items-center justify-center'>
                                                            <ButtonToolbar>
                                                                <OverlayTrigger
                                                                    trigger={['hover', 'focus']}
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Popover className='p-[10px] text-black' id={`popover-trigger-hover-focus`} title={objectQ['additionRechargeQ']}>
                                                                            {objectQ['additionRechargeQ']}
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <Button className='p-0 border-0 ' style={{ background: "none", color: "none" }}>
                                                                        <div className='rounded-full p-[1px] mt-[2px] bg-transparent  border-[2px] border-solid border-black  '>

                                                                            <BsQuestionLg className='h-[10px] w-[10px] text-black flex-shrink-0 ' id='popover-trigger-hover-focus' />

                                                                        </div>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </ButtonToolbar>
                                                        </div>
                                                    </button>
                                                </>
                                                :
                                                <button className='w-full mt-[10px] border-solid border-yellow-500 border-[1px] bg-yellow-200 py-[10px] rounded-[35px] font-bold  text-[17px]' style={{ boxShadow: "0px 2px 4px rgba(25, 25, 25, 0.4), 0px 7px 13px -3px rgba(25, 25, 25, 0.3), inset 0px -3px 0px rgba(25, 25, 25, 0.2)" }} onClick={() => token ? handlePurchase(plan.price, plan.planType, plan.planDuration, plan.balanceUpdater, inde, channelId, plan.credits) : handleSignIn()}>{t('Subscribe')}</button>
                                            }

                                            <ul>
                                                <div className='h-[280px] overflow-y-auto scrollable-line-css-price mt-[25px] text-gray-300'>
                                                    {plan?.features?.map((feature, index) => {
                                                        return <div key={index} className='flex items-start justify-between'>

                                                            <div className='flex items-start justify-between  gap-[15px] mb-[12px]'>
                                                                <BsFillCheckCircleFill className='h-[17px] w-[17px] text-green-400 flex-shrink-0 mt-[6px]' />

                                                                <div className='text-start text-[17px]'>{t(feature[0])}</div>
                                                            </div>
                                                            <ButtonToolbar>
                                                                <OverlayTrigger
                                                                    trigger={['hover', 'focus']}
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Popover className='p-[10px] text-black' id={`popover-trigger-hover-focus-${index}`} title={t(feature[1])}>
                                                                            {t(feature[1])}
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <Button className='p-0 border-0 ' style={{ background: "none", color: "none" }}>
                                                                        <div className='rounded-full p-[1px] mt-[2px] bg-transparent  border-1 border-solid border-white  '>

                                                                            <BsQuestionLg className='h-[10px] w-[10px] text-white flex-shrink-0 ' id='popover-trigger-hover-focus' />

                                                                        </div>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </ButtonToolbar>

                                                        </div>
                                                    })}
                                                    {plan?.notInclude?.map((data, index) => (
                                                        <div key={index} className='flex items-start justify-between mb-[12px]'>
                                                            <div className='flex items-start gap-[15px] '>
                                                                <BsXCircleFill className='h-[17px] w-[17px] text-red-400 flex-shrink-0 mt-[6px]' />
                                                                <div className='text-start text-[17px]'>{t(data[0])}</div>
                                                            </div>
                                                            <ButtonToolbar>
                                                                <OverlayTrigger
                                                                    trigger={['hover', 'focus']}
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Popover className='p-[10px] text-black' id={`popover-trigger-hover-focus-${index}`} title={t(data[1])}>
                                                                            {t(data[1])}
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <Button className='p-0 border-0 ' style={{ background: "none", color: "none" }}>
                                                                        <div className='rounded-full p-[1px] mt-[2px] bg-transparent  border-1 border-solid border-white  '>

                                                                            <BsQuestionLg className='h-[10px] w-[10px] text-white flex-shrink-0 ' id='popover-trigger-hover-focus' />

                                                                        </div>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </ButtonToolbar>
                                                        </div>
                                                    ))}
                                                </div>
                                            </ul>

                                        </div>
                                    </>
                                    : inde === 1 ? <>
                                        <div className='p-[0px] h-full'>
                                            {activePlan?.index === inde && <div className='flex justify-end'>
                                                <div className='px-[15px] border-solid border-green-500 border-[1px] bg-green-200 py-[3px] rounded-[20px] font-bold  text-[12px]' style={{ boxShadow: "0px 2px 4px rgba(25, 25, 25, 0.4), 0px 7px 13px -3px rgba(25, 25, 25, 0.3), inset 0px -3px 0px rgba(25, 25, 25, 0.2)" }} >{t('Current')}</div>
                                            </div>
                                            }
                                            <header className="flex gap-[10px] flex-col items-start  mb-[10px]">

                                                <h3 className=" text-green-400  font-semibold text-[28px]">{plan.planType}</h3>

                                            </header>

                                            <div className="font-bold text-green-200 ">
                                                <div className="h-[100px] flex items-center  gap-[1.5px]">
                                                    <div className="text-[28px] mb-[15px]">$</div>
                                                    <div className='text-[60px] text-white'>{plan.price}</div>
                                                    <div className='items-start flex flex-col'>
                                                        <div className='text-[16px] line-through  text-white'>${plan?.WithoutDiscountPrice}</div>
                                                        <div className='text-[16px] mt-[16px]'>/month</div>
                                                    </div>
                                                </div>

                                            </div>


                                            {activePlan?.index === inde
                                                ?
                                                <>
                                                    <button className='w-full mt-[10px] border-solid border-gray-500 border-[1px] bg-gray-200 py-[10px] rounded-[35px] font-bold  text-[17px]' style={{ boxShadow: "0px 2px 4px rgba(25, 25, 25, 0.4), 0px 7px 13px -3px rgba(25, 25, 25, 0.3), inset 0px -3px 0px rgba(25, 25, 25, 0.2)" }} onClick={() => token ? handleUnSubscribe() : handleSignIn()}>{t('UnSubscribe')}</button>

                                                    <button className='relative w-full mt-[15px] border-solid border-green-500 border-[1px] bg-green-300 py-[10px] rounded-[35px] font-bold  text-[17px]' style={{ boxShadow: "0px 2px 4px rgba(25, 25, 25, 0.4), 0px 7px 13px -3px rgba(25, 25, 25, 0.3), inset 0px -3px 0px rgba(25, 25, 25, 0.2)" }} onClick={() => token ? handleAdditionPurchase(plan.price, activePlan.planId, channelId, inde, plan.credits) : handleSignIn()}>
                                                        <span>{t('Additional Recharge')}</span>
                                                        <div className='absolute top-0 bottom-0 right-[10px] flex items-center justify-center'>
                                                            <ButtonToolbar>
                                                                <OverlayTrigger
                                                                    trigger={['hover', 'focus']}
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Popover className='p-[10px] text-black' id={`popover-trigger-hover-focus`} title={objectQ['additionRechargeQ']}>
                                                                            {objectQ['additionRechargeQ']}
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <Button className='p-0 border-0 ' style={{ background: "none", color: "none" }}>
                                                                        <div className='rounded-full p-[1px] mt-[2px] bg-transparent  border-[2px] border-solid border-black  '>

                                                                            <BsQuestionLg className='h-[10px] w-[10px] text-black flex-shrink-0 ' id='popover-trigger-hover-focus' />

                                                                        </div>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </ButtonToolbar>
                                                        </div>
                                                    </button>

                                                </>
                                                :
                                                <button className='w-full mt-[10px] border-solid border-green-500 border-[1px] bg-green-200 py-[10px] rounded-[35px] font-bold  text-[17px]' style={{ boxShadow: "0px 2px 4px rgba(25, 25, 25, 0.4), 0px 7px 13px -3px rgba(25, 25, 25, 0.3), inset 0px -3px 0px rgba(25, 25, 25, 0.2)" }} onClick={() => token ? handlePurchase(plan.price, plan.planType, plan.planDuration, plan.balanceUpdater, inde, channelId, plan.credits) : handleSignIn()}>{t('Subscribe')}</button>
                                            }
                                            <ul>
                                                <div className='h-[280px] overflow-y-auto scrollable-line-css-price-green mt-[25px] text-gray-300'>
                                                    {plan?.features?.map((feature, index) => (
                                                        <div key={index} className='flex items-start justify-between'>

                                                            <div className='flex items-start justify-between  gap-[15px] mb-[12px]'>
                                                                <BsFillCheckCircleFill className='h-[17px] w-[17px] text-green-400 flex-shrink-0 mt-[6px]' />
                                                                <div className='text-start text-[17px]'>{t(feature[0])}</div>
                                                            </div>
                                                            <ButtonToolbar>
                                                                <OverlayTrigger
                                                                    trigger={['hover', 'focus']}
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Popover className='p-[10px] text-black' id={`popover-trigger-hover-focus-${index}`} title={t(feature[1])}>
                                                                            {t(feature[1])}
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <Button className='p-0 border-0 ' style={{ background: "none", color: "none" }}>
                                                                        <div className='rounded-full p-[1px] mt-[2px] bg-transparent  border-1 border-solid border-white  '>

                                                                            <BsQuestionLg className='h-[10px] w-[10px] text-white flex-shrink-0 ' id='popover-trigger-hover-focus' />

                                                                        </div>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </ButtonToolbar>

                                                        </div>
                                                    ))}
                                                    {plan?.notInclude?.map((data, index) => (
                                                        <div key={index} className='flex items-start justify-between mb-[12px]'>
                                                            <div className='flex items-start gap-[15px] '>
                                                                <BsXCircleFill className='h-[17px] w-[17px] text-red-400 flex-shrink-0 mt-[6px]' />
                                                                <div className='text-start text-[17px]'>{t(data[0])}</div>
                                                            </div>
                                                            <ButtonToolbar>
                                                                <OverlayTrigger
                                                                    trigger={['hover', 'focus']}
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Popover className='p-[10px] text-black' id={`popover-trigger-hover-focus-${index}`} title={t(data[1])}>
                                                                            {t(data[1])}
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <Button className='p-0 border-0 ' style={{ background: "none", color: "none" }}>
                                                                        <div className='rounded-full p-[1px] mt-[2px] bg-transparent  border-1 border-solid border-white  '>

                                                                            <BsQuestionLg className='h-[10px] w-[10px] text-white flex-shrink-0 ' id='popover-trigger-hover-focus' />

                                                                        </div>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </ButtonToolbar>
                                                        </div>
                                                    ))}
                                                </div>
                                            </ul>

                                        </div>
                                    </> : inde === 2 ? <>
                                        <div className='p-[0px] h-full'>
                                            {activePlan?.index === inde && <div className='flex justify-end'>
                                                <div className='px-[15px] border-solid border-blue-500 border-[1px] bg-blue-200 py-[3px] rounded-[20px] font-bold  text-[12px]' style={{ boxShadow: "0px 2px 4px rgba(25, 25, 25, 0.4), 0px 7px 13px -3px rgba(25, 25, 25, 0.3), inset 0px -3px 0px rgba(25, 25, 25, 0.2)" }} >{t('Current')}</div>
                                            </div>
                                            }
                                            <header className="flex gap-[10px] flex-col items-start  mb-[10px]">

                                                <h3 className=" text-blue-400  font-semibold text-[28px]">{plan.planType}</h3>

                                            </header>
                                            <div className="font-bold text-blue-200 ">
                                                <div className="h-[100px] flex items-center  gap-[1.5px]">
                                                    <div className="text-[28px] mb-[15px]">$</div>
                                                    <div className='text-[60px] text-white'>{plan.price}</div>
                                                    <div className='items-start flex flex-col'>
                                                        <div className='text-[16px] line-through  text-white'>${plan?.WithoutDiscountPrice}</div>
                                                        <div className='text-[16px] mt-[16px]'>/month</div>
                                                    </div>
                                                </div>


                                            </div>

                                            {activePlan?.index === inde
                                                ?
                                                <>
                                                    <button className='w-full mt-[10px] border-solid border-gray-500 border-[1px] bg-gray-200 py-[10px] rounded-[35px] font-bold  text-[17px]' style={{ boxShadow: "0px 2px 4px rgba(25, 25, 25, 0.4), 0px 7px 13px -3px rgba(25, 25, 25, 0.3), inset 0px -3px 0px rgba(25, 25, 25, 0.2)" }} onClick={() => token ? handleUnSubscribe() : handleSignIn()}>{t('UnSubscribe')}</button>
                                                    <button className='relative w-full mt-[15px] border-solid border-blue-500 border-[1px] bg-blue-300 py-[10px] rounded-[35px] font-bold  text-[17px]' style={{ boxShadow: "0px 2px 4px rgba(25, 25, 25, 0.4), 0px 7px 13px -3px rgba(25, 25, 25, 0.3), inset 0px -3px 0px rgba(25, 25, 25, 0.2)" }} onClick={() => token ? handleAdditionPurchase(plan.price, activePlan.planId, channelId, inde, plan.credits) : handleSignIn()}>
                                                        <span>{t('Additional Recharge')}</span>
                                                        <div className='absolute top-0 bottom-0 right-[10px] flex items-center justify-center'>
                                                            <ButtonToolbar>
                                                                <OverlayTrigger
                                                                    trigger={['hover', 'focus']}
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Popover className='p-[10px] text-black' id={`popover-trigger-hover-focus`} title={objectQ['additionRechargeQ']}>
                                                                            {objectQ['additionRechargeQ']}
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <Button className='p-0 border-0 ' style={{ background: "none", color: "none" }}>
                                                                        <div className='rounded-full p-[1px] mt-[2px] bg-transparent  border-[2px] border-solid border-black  '>

                                                                            <BsQuestionLg className='h-[10px] w-[10px] text-black flex-shrink-0 ' id='popover-trigger-hover-focus' />

                                                                        </div>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </ButtonToolbar>
                                                        </div>
                                                    </button>
                                                </>
                                                :
                                                <button className='w-full mt-[10px] border-solid border-blue-500 border-[1px] bg-blue-200 py-[10px] rounded-[35px] font-bold  text-[17px]' style={{ boxShadow: "0px 2px 4px rgba(25, 25, 25, 0.4), 0px 7px 13px -3px rgba(25, 25, 25, 0.3), inset 0px -3px 0px rgba(25, 25, 25, 0.2)" }} onClick={() => token ? handlePurchase(plan.price, plan.planType, plan.planDuration, plan.balanceUpdater, inde, channelId, plan.credits) : handleSignIn()}>{t('Subscribe')}</button>
                                            }
                                            <ul>
                                                <div className='h-[280px] overflow-y-auto scrollable-line-css-price-blue mt-[25px] text-gray-300'>
                                                    {plan?.features?.map((feature, index) => (
                                                        <div key={index} className='flex items-start justify-between'>

                                                            <div className='flex items-start justify-between  gap-[15px] mb-[12px]'>
                                                                <BsFillCheckCircleFill className='h-[17px] w-[17px] text-green-400 flex-shrink-0 mt-[6px]' />
                                                                <div className='text-start text-[17px]'>{t(feature[0])}</div>
                                                            </div>
                                                            <ButtonToolbar>
                                                                <OverlayTrigger
                                                                    trigger={['hover', 'focus']}
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Popover className='p-[10px] text-black' id={`popover-trigger-hover-focus-${index}`} title={t(feature[1])}>
                                                                            {t(feature[1])}
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <Button className='p-0 border-0 ' style={{ background: "none", color: "none" }}>
                                                                        <div className='rounded-full p-[1px] mt-[2px] bg-transparent  border-1 border-solid border-white  '>

                                                                            <BsQuestionLg className='h-[10px] w-[10px] text-white flex-shrink-0 ' id='popover-trigger-hover-focus' />

                                                                        </div>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </ButtonToolbar>

                                                        </div>
                                                    ))}
                                                    {plan?.notInclude?.map((data, index) => (
                                                        <div key={index} className='flex items-start justify-between mb-[12px]'>
                                                            <div className='flex items-start gap-[15px] '>
                                                                <BsXCircleFill className='h-[17px] w-[17px] text-red-400 flex-shrink-0 mt-[6px]' />
                                                                <div className='text-start text-[17px]'>{t(data[0])}</div>
                                                            </div>
                                                            <ButtonToolbar>
                                                                <OverlayTrigger
                                                                    trigger={['hover', 'focus']}
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Popover className='p-[10px] text-black' id={`popover-trigger-hover-focus-${index}`} title={t(data[1])}>
                                                                            {t(data[1])}
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <Button className='p-0 border-0 ' style={{ background: "none", color: "none" }}>
                                                                        <div className='rounded-full p-[1px] mt-[2px] bg-transparent  border-1 border-solid border-white  '>

                                                                            <BsQuestionLg className='h-[10px] w-[10px] text-white flex-shrink-0 ' id='popover-trigger-hover-focus' />

                                                                        </div>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </ButtonToolbar>
                                                        </div>
                                                    ))}
                                                </div>
                                            </ul>

                                        </div>
                                    </> : inde === 3 ? <>
                                        <div className='p-[0px] h-full'>
                                            {activePlan?.index === inde && <div className='flex justify-end'>
                                                <div className='px-[15px] border-solid border-pink-500 border-[1px] bg-pink-200 py-[3px] rounded-[20px] font-bold  text-[12px]' style={{ boxShadow: "0px 2px 4px rgba(25, 25, 25, 0.4), 0px 7px 13px -3px rgba(25, 25, 25, 0.3), inset 0px -3px 0px rgba(25, 25, 25, 0.2)" }} >{t('Current')}</div>
                                            </div>
                                            }
                                            <header className="flex gap-[10px] flex-col items-start  mb-[10px]">
                                                <h3 className=" text-pink-300  font-semibold text-[28px]">{plan.planType}</h3>

                                            </header>
                                            <div className="font-bold text-pink-200 ">
                                                <div className="h-[100px] flex items-center  gap-[1.5px]">
                                                    <div className="text-[28px] mb-[15px]">$</div>
                                                    <div className='text-[60px] text-white'>{plan.price}</div>
                                                    <div className='items-start flex flex-col'>
                                                        <div className='text-[16px] line-through  text-white'>${plan?.WithoutDiscountPrice}</div>
                                                        <div className='text-[16px] mt-[16px]'>/month</div>
                                                    </div>
                                                </div>


                                            </div>

                                            {activePlan?.index === inde
                                                ?
                                                <>
                                                    <button className='w-full mt-[10px] border-solid border-gray-500 border-[1px] bg-gray-200 py-[10px] rounded-[35px] font-bold  text-[17px]' style={{ boxShadow: "0px 2px 4px rgba(25, 25, 25, 0.4), 0px 7px 13px -3px rgba(25, 25, 25, 0.3), inset 0px -3px 0px rgba(25, 25, 25, 0.2)" }} onClick={() => token ? handleUnSubscribe() : handleSignIn()}>{t('UnSubscribe')}</button>
                                                    <button className='relative w-full mt-[15px] border-solid border-pink-500 border-[1px] bg-pink-300 py-[10px] rounded-[35px] font-bold  text-[17px]' style={{ boxShadow: "0px 2px 4px rgba(25, 25, 25, 0.4), 0px 7px 13px -3px rgba(25, 25, 25, 0.3), inset 0px -3px 0px rgba(25, 25, 25, 0.2)" }} onClick={() => token ? handleAdditionPurchase(plan.price, activePlan.planId, channelId, inde, plan.credits) : handleSignIn()}>
                                                        <span>{t('Additional Recharge')}</span>
                                                        <div className='absolute top-0 bottom-0 right-[10px] flex items-center justify-center'>
                                                            <ButtonToolbar>
                                                                <OverlayTrigger
                                                                    trigger={['hover', 'focus']}
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Popover className='p-[10px] text-black' id={`popover-trigger-hover-focus`} title={objectQ['additionRechargeQ']}>
                                                                            {objectQ['additionRechargeQ']}
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <Button className='p-0 border-0 ' style={{ background: "none", color: "none" }}>
                                                                        <div className='rounded-full p-[1px] mt-[2px] bg-transparent  border-[2px] border-solid border-black  '>

                                                                            <BsQuestionLg className='h-[10px] w-[10px] text-black flex-shrink-0 ' id='popover-trigger-hover-focus' />

                                                                        </div>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </ButtonToolbar>
                                                        </div>
                                                    </button>
                                                </>
                                                :
                                                <button className='w-full mt-[10px] border-solid border-pink-500 border-[1px] bg-pink-200 py-[10px] rounded-[35px] font-bold  text-[17px]' style={{ boxShadow: "0px 2px 4px rgba(25, 25, 25, 0.4), 0px 7px 13px -3px rgba(25, 25, 25, 0.3), inset 0px -3px 0px rgba(25, 25, 25, 0.2)" }} onClick={() => token ? handlePurchase(plan.price, plan.planType, plan.planDuration, plan.balanceUpdater, inde, channelId, plan.credits) : handleSignIn()}>{t('Subscribe')}</button>
                                            }

                                            <ul>
                                                <div className='h-[280px] overflow-y-auto scrollable-line-css-price-pink mt-[25px] text-gray-300'>
                                                    {plan?.features?.map((feature, index) => (
                                                        <div key={index} className='flex items-start justify-between'>
                                                            <div className={`flex items-start  gap-[15px] mb-[12px] `}>
                                                                <BsFillCheckCircleFill className='h-[17px] w-[17px] text-green-400 flex-shrink-0 mt-[6px]' />
                                                                <div className='text-start text-[17px]'>{t(feature[0])}</div>
                                                            </div>
                                                            <ButtonToolbar>
                                                                <OverlayTrigger
                                                                    trigger={['hover', 'focus']}
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Popover className='p-[10px] text-black' id={`popover-trigger-hover-focus-${index}`} title={t(feature[1])}>
                                                                            {t(feature[1])}
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <Button className='p-0 border-0 ' style={{ background: "none", color: "none" }}>
                                                                        <div className='rounded-full p-[1px] bg-transparent  border-1 border-solid border-white mt-[2px] '>

                                                                            <BsQuestionLg className='h-[10px] w-[10px] text-white flex-shrink-0 ' id='popover-trigger-hover-focus' />

                                                                        </div>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </ButtonToolbar>

                                                        </div>
                                                    ))}
                                                </div>
                                            </ul>

                                        </div>
                                    </> : null}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div >
    )
}

export default Price
