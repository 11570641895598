import React from 'react'
import Header from '../components/Header'
import Features from '../components/Features'
import Price from '../components/Price'
import CreditSection from '../components/CreditSection'

function Setting() {
    const token = localStorage.getItem('eonToken')

    return (
        <div>
            <Header />
            {token && <CreditSection />}
            <Features />
            <Price header={false} />
        </div>
    )
}

export default Setting
