import { getDataAPI } from "../../../utils/FetchData"
import { GLOBALTYPES } from "../GlobalTypes"

export const USER_TYPES = {
    LOADING: "LOADING",
    USER: "USER"
}

export const getUserProfile = (token) => async (dispatch) => {
    try {
        dispatch({ type: USER_TYPES.LOADING, payload: { loading: true } })

        const res = await getDataAPI(`user/getUserProfile`, token)

        dispatch({ type: USER_TYPES.USER, payload: { user: res?.data?.data?.response } })
        dispatch({ type: USER_TYPES.LOADING, payload: { loading: false } })

    }
    catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}

