import { combineReducers } from 'redux'
import auth from './authReducer'
import alert from './alertReducer'
import report from './reportReducer'
import youtube from './youtubeReducer'
import signin from './signInReducer'
import subscription from './subscriptionReducer'
import user from './userReducer'
import unsubscribe from './unsubscribeReducer'
import feature from './featureReducer'
import payment from './paymentReducer'


export default combineReducers({
    auth,
    alert,
    report,
    youtube,
    signin,
    subscription,
    user,
    unsubscribe,
    feature,
    payment
})