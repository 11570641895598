import { SUBSCRIPTION_TYPES } from "../actions/user/Subscription";


const initialState = {
    loading: false,
    // view: '',
    plan: {},
    subscribed_plan: {},
    cancel_subscription_loading: false,
    cancel_subscription_success: false,
    cancel_subscription_error: false
    // past_bid: [],
}

const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBSCRIPTION_TYPES.LOADING:
            return {
                ...state,
                loading: action.payload.loading
            }

        case SUBSCRIPTION_TYPES.PLAN:
            return {
                ...state,
                plan: action.payload.plan
            }
        case SUBSCRIPTION_TYPES.SUBSCRIBED_PLAN:
            return {
                ...state,
                subscribed_plan: action.payload.subscribed_plan
            }
        case SUBSCRIPTION_TYPES.CANCEL_SUBSCRIPTION_LOADING:
            return {
                ...state,
                cancel_subscription_loading: action.payload.loading
            }
        case SUBSCRIPTION_TYPES.CANCEL_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                cancel_subscription_success: action.payload.success
            }
        case SUBSCRIPTION_TYPES.CANCEL_SUBSCRIPTION_ERROR:
            return {
                ...state,
                cancel_subscription_error: action.payload.error
            }
        default:
            return state;
    }
}


export default subscriptionReducer;
