import { getDataAPI, postDataAPI } from "../../../utils/FetchData";
import { GLOBALTYPES } from "../GlobalTypes";
import { getUserProfile } from "./UserDetails";
export const FEATURES_TYPES = {
    LOADING: "LOADING",
    GET_SELECTED_FEATURES: "GET_SELECTED_FEATURES",
}


export const save_analyze_data = ({ showSelectedStates, deleteSelectedStates, blockSelectedStates, channelId, token }) => async (dispatch) => {
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } })
        let showFeatures = showSelectedStates
        let deleteFeatures = deleteSelectedStates
        let blockFeatures = blockSelectedStates
        const res = await postDataAPI(`user/save_analyze_data`, { analyze: { showFeatures, deleteFeatures, blockFeatures }, channelId: channelId }, token)
        dispatch(get_features_data(token, channelId))
        dispatch(getUserProfile(token))


        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                success: res?.data?.message
            }
        })
    } catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}

export const get_features_data = (token, channelId) => async (dispatch) => {
    try {

        dispatch({ type: FEATURES_TYPES.LOADING, payload: { loading: true } })
        const res = await getDataAPI(`user/get_features_data/${channelId}`, token)

        dispatch({
            type: FEATURES_TYPES.GET_SELECTED_FEATURES, payload: {
                get_selected_features: res?.data?.data
            }
        })
        dispatch({ type: FEATURES_TYPES.LOADING, payload: { loading: false } })

    } catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}



