import { USER_TYPES } from "../actions/user/UserDetails";

const initialState = {
    loading: false,
    user: {}
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_TYPES.LOADING:
            return {
                ...state,
                loading: action.payload.loading
            }
        case USER_TYPES.USER:
            return {
                ...state,
                user: action.payload.user
            }
        default:
            return state;
    }
}

export default userReducer