import React, { useEffect } from 'react'
import 'reactjs-popup/dist/index.css';
import { cancelSubscription } from '../../redux/actions/user/Subscription';
import { Audio } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';

function UnsubscribePopUp({ display, onClose }) {
    const dispatch = useDispatch()
    const cancel_subscription_loading = useSelector(state => state.subscription?.cancel_subscription_loading)
    const activePlan = useSelector(state => state.subscription?.subscribed_plan)
    const token = localStorage.getItem('eonToken')

    useEffect(() => {
        const handleClickOutside = (event) => {
            const modalContainer = document.getElementById('unsubscribeModal');

            if (modalContainer && !modalContainer.contains(event.target)) {
                onClose();
            }
        };

        if (display) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [display, onClose]);

    // useEffect(() => {
    //     if (cancel_subscription_success) {
    //         onClose();
    //         navigate('/unsubscribesuccess');
    //     } else if (cancel_subscription_error) {
    //         onClose();
    //         navigate('/error');
    //     }
    // }, [cancel_subscription_success, cancel_subscription_error, navigate, onClose]);

    const handleDisplayChange = () => {
        onClose();
    };

    const handleCancelSubscription = (planId, channelId, index) => {

        dispatch(cancelSubscription({ planId, channelId, index, token }))

    }

    return (
        <>
            <div id="unsubscribeModal" className="fixed flex items-center justify-center  z-50  p-4 md:inset-0 h-[calc(100%-1rem)] max-h-full" style={{ display: display ? 'flex' : 'none' }}>
                <div className="relative w-full max-w-md max-h-full">
                    <div className="relative bg-gray-950 rounded-lg shadow dark:bg-gray-700">
                        {
                            cancel_subscription_loading
                                ?
                                <div className="flex items-center justify-center py-[80px]">
                                    <div className='text-center'>
                                        <Audio
                                            height="100"
                                            width="100"
                                            radius="5"
                                            color="red"
                                            ariaLabel="loading"
                                        />
                                    </div>
                                </div>
                                : (
                                    <>
                                        <button type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200  rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" onClick={handleDisplayChange}>
                                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                            </svg>
                                            <span className="sr-only">Close modal</span>
                                        </button>
                                        <div className="p-6 text-center">
                                            <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                            </svg>
                                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to unsubscribe this product?</h3>


                                            <button className="text-gray-800  bg-white hover:bg-black focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10   mr-[20px]" onClick={() => handleCancelSubscription(activePlan?.planId, activePlan?.channelId, activePlan?.index)}>Yes, I'm sure</button>

                                            <button className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center " onClick={handleDisplayChange}>
                                                No, cancel
                                            </button>
                                        </div>
                                    </>
                                )
                        }
                    </div>
                </div>

            </div>
        </>

    )
}

export default UnsubscribePopUp