import { FEATURES_TYPES } from "../actions/user/FeaturesAction"

const initialState = {
    loading: false,
    get_selected_features: {}
}

const featureReducer = (state = initialState, action) => {
    switch (action.type) {
        case FEATURES_TYPES.LOADING:
            return {
                ...state,
                loading: action.payload.loading
            }

        case FEATURES_TYPES.GET_SELECTED_FEATURES:
            return {
                ...state,
                get_selected_features: action.payload.get_selected_features
            }
        default:
            return state;
    }
}

export default featureReducer;