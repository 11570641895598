import { getDataAPI, postDataAPI } from "../../../utils/FetchData"
import { GLOBALTYPES } from "../GlobalTypes"

export const SUBSCRIPTION_TYPES = {
    LOADING: "LOADING",
    PLAN: "PLAN",
    SUBSCRIBED_PLAN: "SUBSCRIBED_PLAN",
    CANCEL_SUBSCRIPTION_LOADING: "CANCEL_SUBSCRIPTION_LOADING",
    CANCEL_SUBSCRIPTION_SUCCESS: "CANCEL_SUBSCRIPTION_SUCCESS",
    CANCEL_SUBSCRIPTION_ERROR: "CANCEL_SUBSCRIPTION_ERROR"
}




export const getActivePlan = (token, channelId) => async (dispatch) => {
    try {

        dispatch({ type: SUBSCRIPTION_TYPES.LOADING, payload: { loading: true } })
        const res = await getDataAPI(`user/getActivePlan/${channelId}`, token)

        dispatch({ type: SUBSCRIPTION_TYPES.PLAN, payload: { plan: res?.data?.data?.response } })
        dispatch({ type: SUBSCRIPTION_TYPES.SUBSCRIBED_PLAN, payload: { subscribed_plan: res?.data?.data?.responseSubscribedPlan } })
        dispatch({ type: SUBSCRIPTION_TYPES.LOADING, payload: { loading: false } })
    }
    catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}

export const cancelSubscription = ({ planId, channelId, index, token }) => async (dispatch) => {
    try {
        dispatch({ type: SUBSCRIPTION_TYPES.CANCEL_SUBSCRIPTION_LOADING, payload: { loading: true } })

        const res = await postDataAPI(`user/cancel_subscription`, { planId, channelId, index }, token)


        if (res?.data?.status === 200) {
            dispatch({
                type: GLOBALTYPES.UNSUBSCRIBE,
                payload: {
                    unsubscribe: false
                }
            })
            dispatch({ type: SUBSCRIPTION_TYPES.CANCEL_SUBSCRIPTION_LOADING, payload: { loading: false } })
            window.location.href = "/unsubscribesuccess"

            // dispatch({
            //     type: SUBSCRIPTION_TYPES.CANCEL_SUBSCRIPTION_SUCCESS,
            //     payload: {
            //         success: true
            //     }
            // })
        }
        else {
            dispatch({
                type: GLOBALTYPES.UNSUBSCRIBE,
                payload: {
                    unsubscribe: false
                }
            })
            dispatch({ type: SUBSCRIPTION_TYPES.CANCEL_SUBSCRIPTION_LOADING, payload: { loading: false } })
            window.location.href = '/error';

            // dispatch({
            //     type: SUBSCRIPTION_TYPES.CANCEL_SUBSCRIPTION_ERROR,
            //     payload: {
            //         error: true
            //     }
            // })
        }

    } catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}