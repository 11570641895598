
export const planName = {
    one: "Basic",
    two: "Influencer",
    three: "VIP",
    four: "Celebrity"
}
export const plansDefault = [
    {
        planType: planName['one'],
        price: '5',
        credits: '500',
        WithoutDiscountPrice: '8',
        planDuration: '1', //month
        balanceUpdater: '0.5',
        features: [
            ['500 comments screened monthly', 'We will screen a total of the specified amount of comments on your channel monthly, starting from the most recent and moving to the oldest. Any comments identified for removal during this process will be deleted upon your request.'],
            ['1 day frequent comment monitoring', 'From the moment a new comment is posted, it will take a maximum of the time specified for this plan for us to screen. If you require more frequent monitoring to ensure immediate removal of inappropriate comments, please consider our higher-tier plans.'],
            ['Delete comments', "You can delete certain types of comments from your Youtube channel. Once you specify the types of comments you'd like us to remove in 'step 3' below, we will proceed with the deletion."]

        ],
        notInclude: [
            ['Hide users', "If you prefer to hide a particular user's comments so that the user can post and see their comments, but these comments remain invisible to everyone else, you have the option to choose this feature. To activate this service, currently in beta, please send us an email via the 'Support' page above. We will promptly assist you with the setup."],
            ['Collect positive comments', 'We can collect encouraging, positive, or constructive comments for you. This will help you stay focused if you prefer to see only positive feedback, without directly encountering any irrelevant comments on your channel.'],
            ['Customized monitoring', "We will provide you with any customized service you need. If you wish for us to screen personally tailored types of comments, have unlimited options for hiding users, set detection frequency to 1 minute, or anything else, please reach out to us on the 'Support' page at the top. This is currently a beta version."]
        ]
    },
    {
        planType: planName['two'],
        price: '20',
        credits: '2000',
        WithoutDiscountPrice: '30',
        planDuration: '1', //month
        balanceUpdater: '0.5',
        features: [
            ['2000 comments screened monthly', 'We will screen a total of the specified amount of comments on your channel monthly, starting from the most recent and moving to the oldest. Any comments identified for removal during this process will be deleted upon your request.'],
            ['12 hours frequent comment monitoring', 'From the moment a new comment is posted, it will take a maximum of the time specified for this plan for us to screen. If you require more frequent monitoring to ensure immediate removal of inappropriate comments, please consider our higher-tier plans.'],
            ['Delete comments', "You can delete certain types of comments from your Youtube channel. Once you specify the types of comments you'd like us to remove in 'step 3' below, we will proceed with the deletion."],
            ['Hide users', "If you prefer to hide a particular user's comments so that the user can post and see their comments, but these comments remain invisible to everyone else, you have the option to choose this feature. To activate this service, currently in beta, please send us an email via the 'Support' page above. We will promptly assist you with the setup."],
        ],
        notInclude: [
            ['Collect positive comments', 'We can collect encouraging, positive, or constructive comments for you. This will help you stay focused if you prefer to see only positive feedback, without directly encountering any irrelevant comments on your channel.'],
            ['Customized monitoring', "We will provide you with any customized service you need. If you wish for us to screen personally tailored types of comments, have unlimited options for hiding users, set detection frequency to 1 minute, or anything else, please reach out to us on the 'Support' page at the top. This is currently a beta version."]
        ]
    },
    {
        planType: planName['three'],
        price: '50',
        credits: '5000',
        WithoutDiscountPrice: '75',
        planDuration: '1', //month
        balanceUpdater: '0.5',
        features: [
            ['5000 comments screened monthly', 'We will screen a total of the specified amount of comments on your channel monthly, starting from the most recent and moving to the oldest. Any comments identified for removal during this process will be deleted upon your request.'],
            ['5 hours frequent comment monitoring', 'From the moment a new comment is posted, it will take a maximum of the time specified for this plan for us to screen. If you require more frequent monitoring to ensure immediate removal of inappropriate comments, please consider our higher-tier plans.'],
            ['Delete comments', "You can delete certain types of comments from your Youtube channel. Once you specify the types of comments you'd like us to remove in 'step 3' below, we will proceed with the deletion."],
            ['Hide users', "If you prefer to hide a particular user's comments so that the user can post and see their comments, but these comments remain invisible to everyone else, you have the option to choose this feature. To activate this service, currently in beta, please send us an email via the 'Support' page above. We will promptly assist you with the setup."],
            ['Collect positive comments', 'We can collect encouraging, positive, or constructive comments for you. This will help you stay focused if you prefer to see only positive feedback, without directly encountering any irrelevant comments on your channel.'],
        ],
        notInclude: [
            ['Customized monitoring', "We will provide you with any customized service you need. If you wish for us to screen personally tailored types of comments, have unlimited options for hiding users, set detection frequency to 1 minute, or anything else, please reach out to us on the 'Support' page at the top. This is currently a beta version."]
        ]
    },
    {
        planType: planName['four'],
        price: '200',
        credits: '10000',
        WithoutDiscountPrice: '300',
        planDuration: '1', //month
        balanceUpdater: '0.5',
        features: [
            ['10000 comments screened monthly', 'We will screen a total of the specified amount of comments on your channel monthly, starting from the most recent and moving to the oldest. Any comments identified for removal during this process will be deleted upon your request.'],
            ['1 hours frequent comment monitoring', 'From the moment a new comment is posted, it will take a maximum of the time specified for this plan for us to screen. If you require more frequent monitoring to ensure immediate removal of inappropriate comments, please consider our higher-tier plans.'],
            ['Delete comments', "You can delete certain types of comments from your Youtube channel. Once you specify the types of comments you'd like us to remove in 'step 3' below, we will proceed with the deletion."],
            ['Hide users', "If you prefer to hide a particular user's comments so that the user can post and see their comments, but these comments remain invisible to everyone else, you have the option to choose this feature. To activate this service, currently in beta, please send us an email via the 'Support' page above. We will promptly assist you with the setup."],
            ['Collect positive comments', 'We can collect encouraging, positive, or constructive comments for you. This will help you stay focused if you prefer to see only positive feedback, without directly encountering any irrelevant comments on your channel.'],
            ['Customized monitoring', "We will provide you with any customized service you need. If you wish for us to screen personally tailored types of comments, have unlimited options for hiding users, set detection frequency to 1 minute, or anything else, please reach out to us on the 'Support' page at the top. This is currently a beta version."]
        ]
    }
];
// export const plansDefault = [
//     {
//         planType: planName['one'],
//         price: '3',
//         WithoutDiscountPrice: '5',
//         planDuration: '30',
//         balanceUpdater: '0.5',
//         features: [
//             'Lorem Ipsum Dolores',
//             'Life-time Support',

//         ],
//         notInclude: [
//             'Premium Add-Ons',
//             'Fastest Network',
//             'More Options',
//             'Premium Add-Ons',
//             'Fastest Network',
//             'More Options'
//         ]
//     },
//     {
//         planType: planName['two'],
//         price: '10',
//         WithoutDiscountPrice: '15',
//         planDuration: '30',
//         balanceUpdater: '0.5',
//         features: [
//             'Lorem Ipsum Dolores',
//             'Life-time Support',
//             'Premium Add-Ons',
//         ],
//         notInclude: [
//             'Fastest Network',
//             'More Options',
//             'Premium Add-Ons',
//             'Fastest Network',
//             'More Options'
//         ]
//     },
//     {
//         planType: planName['three'],
//         price: '50',
//         WithoutDiscountPrice: '75',
//         planDuration: '30',
//         balanceUpdater: '0.5',
//         features: [
//             'Lorem Ipsum Dolores',
//             'Life-time Support',
//             'Premium Add-Ons',
//             'Fastest Network',
//             'More Options'
//         ],
//         notInclude: [
//             'Premium Add-Ons',
//             'Fastest Network',
//             'More Options'
//         ]
//     },
//     {
//         planType: planName['four'],
//         price: '200',
//         WithoutDiscountPrice: '300',
//         planDuration: '30',
//         balanceUpdater: '0.5',
//         features: [
//             'Lorem Ipsum Dolores',
//             'Life-time Support',
//             'Premium Add-Ons',
//             'Fastest Network',
//             'More Options',
//             'Premium Add-Ons',
//             'Fastest Network',
//             'More Options'
//         ]
//     }
// ];

export const colorCommon = [
    "yellow", "green", "blue", "pink"
]
