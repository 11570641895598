import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';

const youtube_clientId = process.env.REACT_APP_YOUTUBE_CLIENT_ID
const redirect_url = process.env.REACT_APP_YOUTUBE_REDIRECT_URI;




function Home({ refProps }) {
    const { t } = useTranslation();
    const activePlan = useSelector(state => state.subscription?.plan)
    let token = useSelector(state => state.auth.token)
    if (!token) {
        token = localStorage.getItem('eonToken');
    }
    const userProfilePhoto = localStorage.getItem('userProfilePhoto');
    const channelName = localStorage.getItem('selectedChannel');

    const handleYoutubeSignIn = (e) => {
        e.preventDefault();
        const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${youtube_clientId}&redirect_uri=${redirect_url}&scope=https://www.googleapis.com/auth/youtube.force-ssl&response_type=code&access_type=offline`;

        window.location.href = authUrl;
    }

    return (

        <>
            <div className="container max-w-[1450px] my-[50px] text-white " ref={refProps}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row home-container" >
                            <div className='yt-signin flex items-center justify-center  bg-gray-900 p-10  rounded-[10px] ' >
                                {
                                    token ?
                                        <div className='' style={{
                                            boxShadow: "0px 20px 40px 5px rgb(0, 0, 0, 0.6)", borderRadius: "10px"
                                        }}>
                                            <div className='flex items-center justify-center bg-gray-950 p-4  rounded-[10px]'>
                                                <img className="h-[70px] w-[70px] border-3 rounded-[4px] border-solid border-gray-300" src={userProfilePhoto} alt="" />
                                                <div className='flex justify-center items-baseline flex-col gap-[10px]'>
                                                    <h2 className='home-profile-yt-h2 text-[red] font-bold'>
                                                        <span className='font-semibold text-gray-300'>{t('Channel Name')}  :  </span>{channelName}
                                                    </h2>
                                                    <h2 className='home-profile-yt-h2 text-[red] font-bold'>
                                                        <span className='font-semibold text-gray-300'>Credits (subscription)  :  </span>{activePlan?.availableBalance ? activePlan?.availableBalance : 0}
                                                    </h2>
                                                    <h2 className='home-profile-yt-h2 text-[red] font-bold'>
                                                        <span className='font-semibold text-gray-300'>Credits (additional recharge)  :  </span>{activePlan?.availableBalanceAdditional ? activePlan?.availableBalanceAdditional : 0}
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>

                                        :
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

                                            <h4 className='text-white text-[26px]'>{t('Step 1 : Sign In with')} <em >{t('Youtube')}</em> channel</h4>
                                            <button style={{ backgroundColor: "red", color: "white" }} className='yt-signin-button text-[18px] ' onClick={handleYoutubeSignIn}>Sign In</button>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>

    )
}

export default Home
