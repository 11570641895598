import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


function Aboutus() {
    const { t } = useTranslation();

    return (
        <div className="container max-w-[1450px] mt-[0px] ">
            <div className="row bg-gray-900 py-[40px] px-[40px] rounded-[10px]">
                <div className="col-lg-6  align-self-center">
                    <div className="section-heading ">
                        <h4>{t('About')} <em>{t("What We Do")}</em> &amp; {t('Who We Are')}</h4>
                        <img src="assets/images/heading-line-dec.png" alt="" />
                        <p>{t("About Head")}</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 ">
                            <div className="box-item">
                                <h4 ><Link className='text-gray-200' href="/">{t('100% Refund Policy')}</Link></h4>
                                <p className='text-gray-200'>{t('About First Data')}</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="box-item ">
                                <h4><Link className='' to="#">{t('24/7 Support & Help')}</Link></h4>
                                <p className=''>{t('About Second Data')}</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="box-item ">
                                <h4><Link className='' to="#">{t('Custom Plans Available')}</Link></h4>
                                <p className=''>{t('About Third Data')}</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="box-item ">
                                <h4><Link className='' to="#">{t('Collaborative Innovations')}</Link></h4>
                                <p className='  '>{t('About Fourth Data')}</p>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <p>{t('About Footer')}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="right-image">
                        <img src="assets/images/about-right-dec.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Aboutus
