import axios from 'axios'
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
console.log('BACKEND_URL', BACKEND_URL)

export const BASE_URL = BACKEND_URL;

export const postDataAPI = async (url, post, token) => {

    const res = await axios.post(`${BASE_URL}/${url}`, post,
        {
            headers: { Authorization: token }
        })

    return res;
}

export const getDataAPI = async (url, token) => {
    const res = await axios.get(`${BASE_URL}/${url}`,
        {
            headers: { Authorization: token }
        })

    return res;
}
