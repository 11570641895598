import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { logout } from '../redux/actions/AuthAction';
import { useDispatch } from 'react-redux';
import PayPalSubscriptionButton from '../components/plan/Plan';
import { GLOBALTYPES } from '../redux/actions/GlobalTypes';

function Payment() {
    const dispatch = useDispatch()
    const location = useLocation();
    const selectedPrice = location.state?.price;

    useEffect(() => {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {

            }
        })
    }, [dispatch])
    return (
        <>
            <header className=" header-area header-sticky position-fixed max-w-[1450px] mx-auto bg-gray-950  wow slideInDown" data-wow-duration="0.75s" data-wow-delay="0s" >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="main-nav" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Link to="/" className="logo" >
                                    <div className='text-white text-[24px] font-bold'>Youconsult</div>
                                </Link>
                                <div style={{ display: "flex", alignItems: "center", gap: "20px", color: "black", fontWeight: "500" }}>
                                    <Link to="/" className='text-white'>Home</Link>
                                    <div className="gradient-button">
                                        <div id="modal_trigger" onClick={() => dispatch(logout())}>Sign Out</div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
            <div className='paypal-main '>
                <div className='paypal-pop-up shadow-lg shadow-white rounded-[10px] bg-gray-900 text-white'>


                    <>
                        <div className='selected-price-div'>Payment : ${selectedPrice} / month</div>
                        <PayPalSubscriptionButton dataProps={location.state} />
                    </>

                </div>
            </div>

        </>
    )
}

export default Payment


