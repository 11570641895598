export const showName = [
    "Show Inquiry Comments",
    "Show Business / Collaboration Comments",
    "Show Praised Comments",
    "Show Inquiry Comments1",
    "Show Inquiry Comments2",
    "Show Inquiry Comments3",
    "Show Inquiry Comments4",
    "Show Inquiry Comments5",
    "Show Inquiry Comments6",
    "Show Inquiry Comments7",
]

export const deleteName = [
    "Delete Negative Feedback Comments",
    "Delete Sexual,Violent Comments",
    "Delete Political,Advertising Comments",
    "Delete Negative Feedback Comments1",
    "Delete Negative Feedback Comments2",
    "Delete Negative Feedback Comments3",
    "Delete Negative Feedback Comments4",
    "Delete Negative Feedback Comments5",
    "Delete Negative Feedback Comments6",
    "Delete Negative Feedback Comments7",
]

export const blockName = [
    "Block users with Negative Feedback Comments",
    "Block users with Political Comments",
    "Block users with Sexual,Violent Comments",
    "Block users with Political Comments1",
    "Block users with Political Comments2",
    "Block users with Political Comments3",
    "Block users with Political Comments4",
    "Block users with Political Comments5",
    "Block users with Political Comments6",
    "Block users with Political Comments7",
]


export const customFeatureCount = 5