import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SignInModal from './SignInModal';
import { logout } from '../redux/actions/AuthAction';
import { Audio } from 'react-loader-spinner';
import LangDropDown from './LangDropDown';
import { useTranslation } from 'react-i18next'
import i18next from 'i18next';
import axios from 'axios';


function Header() {

    const { t } = useTranslation();
    const token = localStorage.getItem('eonToken')
    const dispatch = useDispatch()
    const location = useLocation()
    const user = JSON.parse(localStorage.getItem("userDetails"));
    const channelName = localStorage.getItem('selectedChannel');
    const userProfilePhoto = localStorage.getItem('userProfilePhoto');
    const userLoading = false
    const [showModal, setShowModal] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [currentCountry, setCurrentCountry] = useState('en')

    useEffect(() => {
        async function currentLocationFun() {

            const response = await axios.get('https://ipapi.co/json/');
            const userCountry = response.data.country_name;
            if (userCountry === 'Korea') {
                setCurrentCountry('ko')
            }
        }
        currentLocationFun()
    }, []);
    const selectedLanguage = localStorage.getItem('languagei18nextLng') || currentCountry;
    const isAuth = localStorage.getItem('eonToken')




    useEffect(() => {
        // Function to handle clicks outside the modal box
        const handleClickOutside = (event) => {
            const menuTrigger = document.querySelector('.menu-trigger');
            if (menuTrigger && !menuTrigger.contains(event.target) && (event.target.tagName !== 'A') && !(event.target.classList.contains('sign-in-button-li')) && !(event.target.classList.contains('gradient-button')) && (event.target.id !== 'modal_trigger') && (event.target.id !== 'userProfileHeader') && (event.target.id !== 'langDropdown') && (event.target.id !== 'langDropdownLi') && (event.target.id !== 'userProfileHeaderPhoto') && (event.target.id !== 'userProfileHeaderChannel') && (event.target.id !== 'modal') && (event.target.id !== 'modal-div-1') && (event.target.id !== 'modal-div-2') && (event.target.id !== 'modal-login-text') && (event.target.id !== 'sign-in-with-youutube') && (event.target.id !== 'popupBody') && (event.target.id !== 'social_login')) {
                setShowMenu(false);
            }

        };


        // Add the event listener when the menu is displayed
        if (showMenu) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        // Clean up the event listener when the menu is closed
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dispatch, showMenu, token, user]);

    useEffect(() => {
        // Change the language when the component mounts
        i18next.changeLanguage(selectedLanguage);

        // Logic to handle language change based on route or any other criteria
    }, [selectedLanguage]);
    const handleClick = (e) => {
        const lang = e.target.value;

        i18next.changeLanguage(lang);
        localStorage.setItem('languagei18nextLng', lang);
    }
    const handleGetButtonClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const handleMenuToggle = () => {
        setShowMenu(!showMenu);
    };
    return (
        <>
            <header className="max-w-[1450px] mx-auto header-area header-sticky bg-gray-950 wow slideInDown" data-wow-duration="0.75s" data-wow-delay="0s">
                <div className="px-[20px]">
                    <div className="row">
                        <div className="col-12">
                            <nav style={!showMenu ? { display: "flex", alignItems: "center", justifyContent: "space-between" } : {}} className={!showMenu ? "main-nav main-nav-flex" : "main-nav"} >


                                <Link to="/" className="logo" style={showMenu ? { marginTop: "30px" } : { marginTop: "" }}>
                                    <div className='text-white text-[24px] font-bold'>Youconsult</div>
                                </Link>
                                <ul className={showMenu ? "nav nav-flex rounded-b-[10px]" : "nav"} id='header-nav-ul' >
                                    <li style={{ cursor: "pointer" }} >
                                        <Link to="/" className={`${location.pathname === '/' ? 'active-link' : ''}`}>
                                            {t('Home')}
                                        </Link>
                                    </li>
                                    <li style={{ cursor: "pointer" }}>
                                        <Link to="/about" className={`${location.pathname === '/about' ? 'active-link' : ''}`}>
                                            {t('About Us')}
                                        </Link>
                                    </li>
                                    <li style={{ cursor: "pointer" }}>
                                        <Link to="/support" className={`${location.pathname === '/support' ? 'active-link' : ''}`}>
                                            {t('Support')}
                                        </Link>
                                    </li>
                                    <li style={{ cursor: "pointer" }}>
                                        <Link to="/faq" className={`${location.pathname === '/faq' ? 'active-link' : ''}`}>
                                            {t('FAQ')}
                                        </Link>
                                    </li>
                                    <li style={{ cursor: "pointer" }}>
                                        <Link to="/history" className={`${location.pathname === '/history' ? 'active-link' : ''}`}>
                                            {t('History')}
                                        </Link>
                                    </li>
                                    <li style={{ cursor: "pointer" }}>
                                        <Link to="/setting" className={`${location.pathname === '/setting' ? 'active-link' : ''}`}>
                                            {t('Setting')}
                                        </Link>
                                    </li>

                                    <li className='flex items-center justify-center' id='userProfileHeader' style={!isAuth ? {
                                        display
                                            : "none"
                                    } : { display: "flex" }}>
                                        {isAuth &&
                                            !userLoading && userProfilePhoto && (
                                                <div
                                                    className="flex items-center justify-center rounded-[5px]  text-white bg-gradient-to-r from-teal-600 via-teal-600 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50  font-medium text-sm px-[14px] py-[10px] text-center  gap-[5px] h-[44px]"
                                                >
                                                    {/* <button type="button" class="">Teal</button> */}
                                                    <img className='h-[25px] rounded-sm w-[25px]' src={userProfilePhoto} alt="user" id='userProfileHeaderPhoto' />
                                                    <div className='text-white font-bold text-[14px]' id='userProfileHeaderChannel'>
                                                        {channelName}
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            isAuth && userLoading && (
                                                <div className="">
                                                    <div className='text-center'>
                                                        <Audio
                                                            height="35"
                                                            width="35"
                                                            radius="5"
                                                            color="red"
                                                            ariaLabel="loading"
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </li>


                                    <li id='langDropdownLi'>
                                        <LangDropDown selectedLanguage={selectedLanguage} onChange={(e) => handleClick(e)} />
                                    </li>
                                    <li className='sign-in-button-li'>
                                        <div className="gradient-button" >
                                            {isAuth ?
                                                <div id="modal_trigger" className="rounded-none" onClick={() => dispatch(logout())}> {t('Sign Out')}</div>
                                                : <>
                                                    <div id="modal_trigger" style={{ height: "44px" }} onClick={handleGetButtonClick}> {t('Sign In Now')}</div>
                                                    <SignInModal display={showModal} onClose={handleCloseModal} />
                                                </>
                                            }
                                        </div>
                                    </li>
                                </ul>
                                <Link className="menu-trigger" onClick={handleMenuToggle}>
                                    <span>{t('Menu')}</span>
                                </Link>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
