import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'

const youtube_clientId = process.env.REACT_APP_YOUTUBE_CLIENT_ID
const redirect_url = process.env.REACT_APP_YOUTUBE_REDIRECT_URI;

function SignInModal({ display, onClose }) {
  const { t } = useTranslation();
  const handleDisplayChange = () => {
    onClose();
  };



  useEffect(() => {
    const handleClickOutside = (event) => {
      const modalContainer = document.getElementById('modal');

      if (modalContainer && !modalContainer.contains(event.target)) {
        onClose();
      }
    };

    if (display) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [display, onClose]);


  const handleYoutubeSignIn = (e) => {
    e.preventDefault();
    const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${youtube_clientId}&redirect_uri=${redirect_url}&scope=https://www.googleapis.com/auth/youtube.force-ssl&response_type=code&access_type=offline`;
    window.location.href = authUrl;
  }


  return (
    <>
      {display && <div className="modal-backdrop"></div>}
      <div id="modal" className="popupContainer bg-gray-900 rounded-[5px] " style={{ display: display ? 'block' : 'none' }}>
        <div className='flex items-center justify-center flex-col ' id='modal-div-1'>

          <div id='modal-div-2' className="popupHeader w-full mb-[10px] bg-gray-800 text-white rounded-[5px] border-none">
            <span id='modal-login-text' className="header_title">{t('Login')}</span>
            <div onClick={handleDisplayChange} className="modal_close rounded-r-[5px] h-full">
              <svg className="flex-shrink-0 w-5  text-white  " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </div>
          </div>

          <section id='popupBody' className="popupBody">
            <div id='social_login' className="social_login">
              <button id='sign-in-with-youutube' className='bg-[red] text-white font-bold px-[20px] py-[8px] rounded-[6px]' onClick={handleYoutubeSignIn}>Sign In With Youtube</button>
            </div>
          </section>
        </div>
      </div>
    </>

  );
}

export default SignInModal;
