import { GLOBALTYPES } from "../actions/GlobalTypes";

const initialState = {
    unsubscribe: false,
}

const unSubscribeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GLOBALTYPES.UNSUBSCRIBE:
            return {
                ...state,
                unsubscribe: action.payload.unsubscribe
            }
        default:
            return state;
    }
}

export default unSubscribeReducer