import React, { useEffect, useRef } from 'react'
import Header from '../components/Header'
import Home from '../components/Home'
import Price from '../components/Price'
import Aboutus from '../components/Aboutus'
import Footer from '../components/Footer'
import Features from '../components/Features'
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import VideosSection from '../components/VideosSection'
import { useDispatch, useSelector } from 'react-redux'
import { GLOBALTYPES } from '../redux/actions/GlobalTypes'
import { useLocation, useNavigate } from 'react-router-dom'
import { PAYMENT_TYPES } from '../redux/actions/user/PaymentAction'

function Index() {
    const dispatch = useDispatch()
    const refVar = useSelector(state => state.payment?.refLoading);
    const planRef = useRef(null);

    useEffect(() => {
        if (refVar) {
            planRef.current?.scrollIntoView({ behavior: 'auto' });
            dispatch({
                type: PAYMENT_TYPES.REFERNCE,
                payload: {
                    refLoading: false
                }
            })
        }
    }, []);

    const refAdditionalRechargeVar = useSelector(state => state.payment?.additionRechargeRefLoading);
    const additionRechargeRef = useRef(null);
    useEffect(() => {
        if (refAdditionalRechargeVar) {
            additionRechargeRef.current?.scrollIntoView({ behavior: 'auto' });
            dispatch({
                type: PAYMENT_TYPES.ADDITIONAL_RECHARGE_REFERNCE,
                payload: {
                    additionRechargeRefLoading: false
                }
            })
        }
    }, []);

    useEffect(() => {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                success: ''
                // user: res.data.data.response,
            }
        })
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: ''
                // user: res.data.data.response,
            }
        })

        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');
        const channelId = queryParams.get('channelId');
        const channelName = queryParams.get('chnnelName');
        const image = queryParams.get('image');

        if (token) {
            let localStorageToken = localStorage.getItem("eonToken")
            if (!localStorageToken) {
                dispatch({
                    type: GLOBALTYPES.ALERT,
                    payload: {
                        success: 'Login Successfully'
                        // user: res.data.data.response,
                    }
                })
            }
            localStorage.setItem("firstLogin", true)
            localStorage.setItem("eonToken", token)
            localStorage.setItem("isYoutubeSignIn", true)
            localStorage.setItem('selectedChannel', channelName);
            localStorage.setItem('selectedChannelId', channelId);
            localStorage.setItem('userProfilePhoto', image);
            dispatch({
                type: GLOBALTYPES.AUTH,
                payload: {
                    token: token,
                    // user: res.data.data.response,
                }
            })


        }

    }, [dispatch])

    return (
        <>
            <Header />
            <ParallaxProvider>

                <ParallaxBanner
                    layers={[{ image: '/assets/images/Img1.jpg', speed: -20 }]}
                    className="aspect-[2/1.5] parallax-scolling"
                />
            </ParallaxProvider>
            <ParallaxProvider>

                <ParallaxBanner
                    layers={[{ image: '/assets/images/Image2.jpg', speed: -20 }]}
                    className="aspect-[2/1.5] parallax-scolling"
                />
            </ParallaxProvider>

            <Home refProps={additionRechargeRef} />

            <div id="pricing" className="pricing-tables" ref={planRef}>
                <Price header={true} />
            </div>

            <div id="features" className="fetures section">
                <Features header={true} />
            </div>

            <VideosSection />
            <div id="about" className="about-us section ">
                <Aboutus />
            </div>
            <Footer />
        </>
    )
}

export default Index
