import { getDataAPI, postDataAPI } from "../../../utils/FetchData"
import { GLOBALTYPES } from "../GlobalTypes"

export const YOUTUBE_TYPES = {
    LOADING: "LOADING",
    INFLUENCER_DETAILS: "INFLUENCER_DETAILS",
    CHANNELID: "CHANNELID",
}

export const youtubSignIn = (token) => async (dispatch) => {
    try {
        dispatch({ type: YOUTUBE_TYPES.LOADING, payload: { loading: true } })

        const res = await getDataAPI(`user/youtubeMultipleSignIn`, token)


        if (res.data.status === 200) {
            localStorage.setItem("isYoutubeSignIn", true)
        }


        dispatch({ type: YOUTUBE_TYPES.LOADING, payload: { loading: false } })
        dispatch(getUserYoutubeDetails(token))

    }
    catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}


export const getUserYoutubeDetails = (token) => async (dispatch) => {
    try {
        dispatch({ type: YOUTUBE_TYPES.LOADING, payload: { loading: true } })

        const res = await getDataAPI(`user/getUserYoutubeDetails`, token)


        for (const channel of res?.data?.data?.response?.youtubeChannels) {
            if (channel.isUse) {
                const selectedChannelTitle = channel.channelTitle;
                const selectedChannelId = channel.channelId;
                localStorage.setItem('selectedChannel', selectedChannelTitle);
                localStorage.setItem('selectedChannelId', selectedChannelId);
                break; // No need to continue searching once the channel is found
            }
        }
        dispatch({
            type: YOUTUBE_TYPES.INFLUENCER_DETAILS,
            payload: {
                influencer_details: res?.data?.data?.response
            }
        })
        dispatch({ type: YOUTUBE_TYPES.LOADING, payload: { loading: false } })
    } catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}


export const updateYoutubeChannelStatus = (channelId, token) => async (dispatch) => {
    try {


        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } })

        const res = await postDataAPI(`user/updateYoutubeChannelStatus`, { channelId }, token)
        for (const channel of res?.data?.data?.updateResult?.youtubeChannels) {
            if (channel.isUse) {
                const selectedChannelTitle = channel.channelTitle;
                const selectedChannelId = channel.channelId;
                localStorage.setItem('selectedChannel', selectedChannelTitle);
                localStorage.setItem('selectedChannelId', selectedChannelId);
                dispatch({ type: YOUTUBE_TYPES.CHANNELID, payload: { channelId: selectedChannelId } })


                break; // No need to continue searching once the channel is found
            }
        }

        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } })

    } catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}


export const youtube_signin_callback = (token, code) => async (dispatch) => {
    try {
        dispatch({ type: YOUTUBE_TYPES.LOADING, payload: { loading: true } })

        const res = await postDataAPI(`user/youtube_signin_callback`, { code }, token)

        if (res.data.status === 200) {
            localStorage.setItem("isYoutubeSignIn", true)
        }
        dispatch({ type: YOUTUBE_TYPES.LOADING, payload: { loading: false } })

    } catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}


export const getUserProfileYoutubeStatus = (token) => async (dispatch) => {
    try {
        dispatch({ type: YOUTUBE_TYPES.LOADING, payload: { loading: true } })

        const res = await getDataAPI(`user/getUserProfile`, token)

        if (res?.data?.data?.response?.isYoutubeSignIn === true) {
            localStorage.setItem("isYoutubeSignIn", true)
            dispatch(getUserYoutubeDetails(token))

        }
        dispatch({ type: YOUTUBE_TYPES.LOADING, payload: { loading: false } })

    }
    catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}
