import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';


function Footer() {
    const { t } = useTranslation();
    return (
        <>
            <div className="bg-gray-950 mt-[50px]  font-bold shadow-sm">

                <div className="p-4  max-w-[1450px] m-auto shadow md:px-6 md:py-8 ">
                    <div className="sm:flex sm:items-center sm:justify-between py-[16px]">
                        <Link to="/" className="flex items-center mb-4 sm:mb-0">
                            <span className="text-gray-500 self-center text-xl font-semibold whitespace-nowrap dark:text-white">Youconsult</span>
                        </Link>
                        <ul className="flex flex-wrap items-center mb-6 sm:mb-0">
                            <li>
                                <Link to="/" className="mr-4 text-sm text-gray-500 hover:underline hover:text-red-600 md:mr-6 dark:text-gray-400">{t('About')}</Link>
                            </li>
                            <li>
                                <Link to="/privacypolicy" className="mr-4 text-sm text-gray-500 hover:underline hover:text-red-600 md:mr-6 dark:text-gray-400">{t('Privacy Policy')}</Link>
                            </li>
                            <li>
                                <Link to="/"
                                    className="mr-4 text-sm text-gray-500 hover:underline hover:text-red-600 md:mr-6 dark:text-gray-400">{t('Licensing')}</Link>
                            </li>
                            <li>
                                <Link to="/" className="text-sm text-gray-500 hover:underline hover:text-red-600 dark:text-gray-400">{t('Contact')}</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="sm:flex sm:items-center sm:justify-between mt-[50px] py-[16px]">
                        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">{t('All Rights Reserved')} : © 2023 <Link to="/" className="hover:underline hover:text-red-600">Youconsult</Link>.
                        </span>
                        <ul className="flex flex-wrap items-center mb-6 sm:mb-0">
                            <li>
                                <Link to="/" className="mr-4 text-sm text-gray-500 hover:underline hover:text-red-600 md:mr-6 dark:text-gray-400">Twitter</Link>
                            </li>
                            <li>
                                <Link to="/" className="mr-4 text-sm text-gray-500 hover:underline hover:text-red-600 md:mr-6 dark:text-gray-400">LinkedIn
                                </Link>
                            </li>

                            <li>
                                <Link to="/" className="text-sm text-gray-500 hover:underline hover:text-red-600 dark:text-gray-400">Github</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
