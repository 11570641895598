import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get_features_data } from '../redux/actions/user/FeaturesAction'

function CreditSection() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const activePlan = useSelector(state => state.subscription?.plan)
    const token = localStorage.getItem('eonToken')
    const channelId = localStorage.getItem('selectedChannelId')
    const selectedChannel = localStorage.getItem('selectedChannel')
    const get_selected_features = useSelector(state => state.feature?.get_selected_features)
    const userProfilePhoto = localStorage.getItem('userProfilePhoto');


    useEffect(() => {
        dispatch(get_features_data(token, channelId));
    }, [channelId, dispatch, token])

    return (
        <div className='max-w-[1450px] mx-auto bg-gray-900 rounded-[10px]'>
            <div className='p-[40px]'>
                <div className='shadow-sm max-w-[420px] mx-auto ' >
                    <div className='flex items-center justify-center bg-gray-950 p-4 rounded-[10px] text-[14px] tracking-[1px]'>
                        <img className="h-[70px] w-[70px] border-3 rounded-[4px] border-solid border-gray-300" src={userProfilePhoto} alt="" />
                        <div className='flex justify-center items-baseline flex-col gap-[10px]'>
                            <h2 className='home-profile-yt-h2 text-[red] font-bold'>
                                <span className='font-semibold text-gray-300'>{t('Channel Name')}  :  </span>{selectedChannel}
                            </h2>
                            <h2 className='home-profile-yt-h2 text-[red] font-bold'>
                                <span className='font-semibold text-gray-300'>Credits (subscription)  :  </span>{activePlan ? activePlan?.availableBalance : 0}
                            </h2>
                            <h2 className='home-profile-yt-h2 text-[red] font-bold'>
                                <span className='font-semibold text-gray-300'>Credits (additional recharge)  :  </span>{activePlan ? activePlan?.availableBalanceAdditional : 0}
                            </h2>
                        </div>
                    </div>
                    <div className='bg-gray-400 border-t-[1px] border-white border-solid px-[20px] py-[14px] rounded-b-[10px]  flex flex-col items-start justify-center '>

                        <h2 className="mb-[6px] text-[17px] font-bold text-black ">{t('Selected Features')} :</h2>
                        <ul className="max-w-md  text-[14px] text-black  list-disc list-inside ">
                            {
                                get_selected_features?.features?.length
                                    || get_selected_features?.customeResponse?.length
                                    ?
                                    <>
                                        {
                                            get_selected_features?.features?.length > 0 &&
                                            get_selected_features?.features.map((feature) => {
                                                return <li>
                                                    {t(feature)}
                                                </li>
                                            })
                                        }
                                        {
                                            get_selected_features?.customeResponse?.length > 0 &&
                                            get_selected_features?.customeResponse.map((custome) => {
                                                return <li>
                                                    {custome}
                                                </li>
                                            })
                                        }

                                    </>
                                    :
                                    <li>
                                        No Selected Data
                                    </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreditSection