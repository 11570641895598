import axios from 'axios';
import React, { useEffect, useState } from 'react'

function LangDropDown(props) {

    const [currentCountry, setCurrentCountry] = useState('en')

    useEffect(() => {
        async function currentLocationFun() {
            const response = await axios.get('https://ipapi.co/json/');
            const userCountry = response.data.country_name;
            if (userCountry === 'Korea') {
                setCurrentCountry('ko')
            }
        }
        currentLocationFun()
    }, []);
    let lang = localStorage.getItem("languagei18nextLng")
    lang = lang ? lang : currentCountry
    return (
        <select id='langDropdown' value={lang} onChange={props.onChange} name="" className='flex items-center justify-center rounded-[5px]  text-white bg-gradient-to-r from-teal-600 via-teal-600 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50  font-medium text-sm px-[14px] py-[10px] text-center h-[44px] hover:bg-red-200 ' >
            <option value={'en'} className='rounded-[5px] bg-teal-500 hover:bg-red-200'>English</option>
            <option value={'ko'} className='rounded-[5px] bg-teal-500 hover:bg-red-200'>한국인</option>
        </select>
    )
}

export default LangDropDown