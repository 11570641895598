import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Index from '../pages/Index'
import History from '../pages/History'
import Payment from '../pages/Payment'
import About from '../pages/About'
import Setting from '../pages/Setting'
import Faq from '../pages/Faq'
import Support from '../pages/Support'
import Error from '../pages/Error'
import AdditionalRecharge from '../pages/AdditionalRecharge'
import Success from '../pages/Success'
import UnsubscribeSuccess from '../pages/UnsubscribeSuccess'
import PrivacyPolicy from '../pages/PrivacyPolicy'


function AuthRoutes() {
    return (

        <Routes >
            <Route path='' element={<Index />} />
            <Route path='payment' element={<Payment />} />
            <Route path='about' element={<About />} />
            <Route path='history' element={<History />} />
            <Route path='setting' element={<Setting />} />
            <Route path='faq' element={<Faq />} />
            <Route path='support' element={<Support />} />
            <Route path='additionalrecharge' element={<AdditionalRecharge />} />
            <Route path='success' element={<Success />} />
            <Route path='unsubscribesuccess' element={<UnsubscribeSuccess />} />
            <Route path='privacypolicy' element={<PrivacyPolicy />} />
            <Route path='error' element={<Error />} />
        </Routes>
    )
}

export default AuthRoutes
