import { PAYMENT_TYPES } from "../actions/user/PaymentAction";

const initialState = {
    loading: false,
    refLoading: false,
    additionRechargeRefLoading: false,
}

const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAYMENT_TYPES.PAYMENT:
            return {
                ...state,
                loading: action.payload.loading
            }
        case PAYMENT_TYPES.REFERNCE:
            return {
                ...state,
                refLoading: action.payload.refLoading
            }
        case PAYMENT_TYPES.ADDITIONAL_RECHARGE_REFERNCE:
            return {
                ...state,
                additionRechargeRefLoading: action.payload.additionRechargeRefLoading
            }
        default:
            return state;
    }
}

export default paymentReducer