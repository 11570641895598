import React from 'react';
import { PayPalButton } from "react-paypal-button-v2";
import { GLOBALTYPES } from '../../redux/actions/GlobalTypes';
import { useDispatch } from 'react-redux';
import { getOrderPaypalDetails } from '../../redux/actions/user/PaymentAction';
import { useNavigate } from 'react-router-dom';
const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
const basicPlanId = process.env.REACT_APP_BASIC;
const influencerPlanId = process.env.REACT_APP_INFLUENCER;
const vipPlanId = process.env.REACT_APP_VIP;
const celebrityPlanId = process.env.REACT_APP_CELEBRITY;
let paypalPlanId = celebrityPlanId


function PayPalSubscriptionButton({ dataProps }) {

    const navigate = useNavigate();

    if (process.env.REACT_APP_REACT_ENV === 'development') {

        paypalPlanId = process.env.REACT_APP_SUBSCRIPTION_ID
        // paypalPlanId = 'P-78697581L0618024JMVGQTEQ'
    }
    else {
        if (dataProps.index === 0) {
            paypalPlanId = basicPlanId
        }
        else if (dataProps.index === 1) {
            paypalPlanId = influencerPlanId
        }
        else if (dataProps.index === 2) {
            paypalPlanId = vipPlanId
        }
        else if (dataProps.index === 3) {
            paypalPlanId = celebrityPlanId
        }
    }

    const dispatch = useDispatch()
    const token = localStorage.getItem('eonToken')

    const paypalSubscribe = (data, actions) => {
        return actions.subscription.create({
            'plan_id': paypalPlanId,
        });
    };
    const paypalOnError = (err) => {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: 'Please try again.'
            }
        })
    }
    const paypalOnApprove = async (data, detail) => {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {

            }
        })
        data.price = dataProps.price
        data.planType = dataProps.planType
        data.planDuration = dataProps.planDuration
        data.balanceUpdater = dataProps.balanceUpdater
        data.index = dataProps.index
        data.channelId = dataProps.channelId
        data.credits = dataProps.credits

        dispatch(await getOrderPaypalDetails({ data, token })).then(() => {
            navigate('/')
        }).catch((err) => {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: err?.response?.data?.message
                }
            })
        })

    };
    return (
        <PayPalButton

            createSubscription={(data, details) => paypalSubscribe(data, details)}
            onApprove={(data, details) => paypalOnApprove(data, details)}
            onError={(err) => paypalOnError(err)}
            catchError={(err) => paypalOnError(err)}
            onCancel={(err) => paypalOnError(err)}

            options={{
                clientId: clientId,
                vault: true,
                intent: 'subscription'
            }}
            style={{
                shape: 'rect',
                color: 'gold',
                layout: 'vertical',
                label: 'subscribe'
            }}
        />
    );
}

export default PayPalSubscriptionButton; 
