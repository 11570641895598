import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { logout } from '../redux/actions/AuthAction';
import { useDispatch } from 'react-redux';
import { additionalRecharge } from '../redux/actions/user/PaymentAction';
import axios from 'axios';
import { BASE_URL } from '../utils/FetchData';
import { GLOBALTYPES } from '../redux/actions/GlobalTypes';
const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

function AdditionalRecharge() {
  const dispatch = useDispatch()
  const location = useLocation();
  const navigate = useNavigate();
  const selectedPrice = location.state?.price;
  const channelId = location.state?.channelId;
  const planId = location.state?.planId;
  const index = location.state?.index;
  const credits = location.state?.credits;
  const token = localStorage.getItem('eonToken')


  useEffect(() => {
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: {

      }
    })
  }, [dispatch])
  const createOrder = async (data, actions) => {
    try {
      const response = await axios.post(`${BASE_URL}/user/create_order`, { selectedPrice },
        {
          headers: { Authorization: token }
        });
      return response.data.id;
    } catch (error) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: {
          error: 'Order creation was unsuccessful.'
        }
      })
    }
  };

  const onApprove = async (data, actions) => {
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: {

      }
    })
    data.planId = planId
    data.channelId = channelId
    data.index = index
    data.price = selectedPrice
    data.credits = credits
    dispatch(await additionalRecharge({ data, token })).then(() => {
      navigate('/')
    }).catch((err) => {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: {
          error: err?.response?.data?.message
        }
      })
    })
  }

  return (
    <>
      <header className=" header-area header-sticky max-w-[1450px] mx-auto position-fixed bg-gray-950  wow slideInDown" data-wow-duration="0.75s" data-wow-delay="0s" >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="main-nav" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Link to="/" className="logo" >
                  <div className='text-white text-[24px] font-bold'>Youconsult</div>
                </Link>
                <div style={{ display: "flex", alignItems: "center", gap: "20px", color: "black", fontWeight: "500" }}>
                  <Link to="/" className='text-white'>Home</Link>
                  <div className="gradient-button">
                    <div id="modal_trigger" onClick={() => dispatch(logout())}>Sign Out</div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <div className='paypal-main '>
        <div className='paypal-pop-up shadow-lg shadow-white rounded-[10px] bg-gray-900 text-white'>

          <>
            <div className='selected-price-div'>Payment : ${selectedPrice}</div>
            <PayPalScriptProvider options={{
              'client-id': clientId,
              currency: "USD"
            }}>
              <PayPalButtons
                createOrder={createOrder}
                onApprove={onApprove}
              />
            </PayPalScriptProvider>
          </>

        </div>
      </div>

    </>
  )
}

export default AdditionalRecharge