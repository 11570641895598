import './App.css';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode';
import AuthRoutes from './routing/AuthRoutes';
import Alert from './components/alert/alert.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import SignIn from './components/modal/SignIn';
import { logout } from './redux/actions/AuthAction';
import UnSubscribe from './components/modal/Unsubscribe';


function App() {
  const signin = useSelector((state) => state.signin).signin;
  const unsubscribe = useSelector((state) => state.unsubscribe)?.unsubscribe;

  const token = localStorage.getItem('eonToken')
  const dispatch = useDispatch()

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (token) {
        const decodedToken = jwt_decode(token);
        const currentTime = Math.floor(Date.now() / 1000);

        if (decodedToken.exp <= currentTime) {
          dispatch(logout());
        }
      }
    };

    checkTokenExpiration();
  }, [dispatch, token]);

  return (
    <>
      <BrowserRouter >
        {signin && !token && <SignIn />}
        {unsubscribe && token && <UnSubscribe />}

        <Alert />
        <Routes>
          <>
            <Route path='/*' element={<AuthRoutes />} />
            <Route path='*' element={<Navigate to='/' />} />
          </>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
