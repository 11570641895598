import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { SUBSCRIPTION_TYPES } from '../redux/actions/user/Subscription';
import { Link } from 'react-router-dom';
import { BsXCircleFill } from 'react-icons/bs';

function Error() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({
            type: SUBSCRIPTION_TYPES.CANCEL_SUBSCRIPTION_ERROR,
            payload: {
                error: false
            }
        })
    }, [dispatch]);
    return (
        <div className="bg-gray-900 h-screen flex items-center">
            <div className="bg-gray-300 p-6 rounded-[10px] w-[400px] md:mx-auto">
                <BsXCircleFill className='h-[60px] w-[60px] text-red-600  mb-[20px] mx-auto' />
                <div className="text-center">
                    <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">Something Went Wrong</h3>
                    <p className="text-gray-900 mt-[8px]">It seems like something went wrong, We apologize for the inconvenience.</p>
                    <div className="py-10 text-center">
                        <Link to="/" className="px-12 rounded-[10px] bg-gray-900 hover:bg-red-500 text-white font-semibold py-3">
                            GO BACK
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error