import { postDataAPI } from "../../../utils/FetchData";
import { GLOBALTYPES } from "../GlobalTypes";
export const PAYMENT_TYPES = {
    PAYMENT: "PAYMENT",
    REFERNCE: "REFERNCE",
    ADDITIONAL_RECHARGE_REFERNCE: "ADDITIONAL_RECHARGE_REFERNCE"

    // GET_SELECTED_FEATURES: "GET_SELECTED_FEATURES",
}

export const getOrderPaypalDetails = async ({ data, token }) => async (dispatch) => {
    try {
        dispatch({ type: PAYMENT_TYPES.PAYMENT, payload: { loading: true } })

        const res = await postDataAPI(`user/getPaypalOrderDetails`, data, token)
        dispatch({
            type: PAYMENT_TYPES.PAYMENT,
            payload: {
                loading: false
            }
        })
        dispatch({
            type: PAYMENT_TYPES.REFERNCE,
            payload: {
                refLoading: true
            }
        })
        dispatch({
            type: PAYMENT_TYPES.ADDITIONAL_RECHARGE_REFERNCE,
            payload: {
                additionRechargeRefLoading: true
            }
        })
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                success: res?.data?.message
            }
        })

    }
    catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}

export const additionalRecharge = async ({ data, token }) => async (dispatch) => {
    try {
        dispatch({ type: PAYMENT_TYPES.PAYMENT, payload: { loading: true } })

        const res = await postDataAPI(`user/paypal_addition_recharge`, data, token)


        dispatch({
            type: PAYMENT_TYPES.PAYMENT,
            payload: {
                loading: false
            }
        })
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                success: res?.data?.message
            }
        })
    } catch (err) {
        console.log("err", err);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err?.response?.data?.message
            }
        })
    }
}





