import React, { useState } from 'react';
import Header from '../components/Header';
import { postDataAPI } from '../utils/FetchData';
import { useDispatch } from 'react-redux';
import { GLOBALTYPES } from '../redux/actions/GlobalTypes';
import { useTranslation } from 'react-i18next'


const Support = () => {
    const { t } = useTranslation();
    const token = localStorage.getItem('eonToken')
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        email: '',
        subject: '',
        message: '',
    });

    const { email, subject, message } = formData;

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        function validateEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        }

        const isValid = validateEmail(email);

        if (!isValid) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: 'Please enter valid email.'
                }
            })
            return;

        }

        // Check if all required fields are filled
        if (!email || !subject || !message) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: 'Please fill in all fields.'
                }
            })

            return;
        }

        try {
            // Make an API request here
            if (token) {

                const response = await postDataAPI('user/contact/supportDetails', {
                    email,
                    subject,
                    message,
                }, token);

                if (response.data) {
                    dispatch({
                        type: GLOBALTYPES.ALERT,
                        payload: {
                            success: response.data.message
                        }
                    })
                }
                setFormData({
                    email: '',
                    subject: '',
                    message: '',
                });

            }
            else {
                dispatch({
                    type: GLOBALTYPES.SIGNIN,
                    payload: {
                        signin: true
                    }
                })

            }

            // Handle the API response as needed

            // Optionally, you can reset the form fields here

        } catch (error) {
            // Handle API request error
            console.error('API Request Error:', error);
            alert('An error occurred while submitting the form.');
        }
    };

    return (
        <>
            <Header />
            <div className="min-h-screen bg-gray-900 py-6 flex flex-col justify-center sm:py-12">
                <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                    <div className="absolute inset-0 bg-orange-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
                    <div className="relative px-4 py-10 bg-gray-950 shadow-lg sm:rounded-3xl sm:p-20">
                        <div className="max-w-md mx-auto">
                            <div>
                                <h1 className="text-2xl w-96 text-white font-semibold">{t('Contact Us')}</h1>
                            </div>
                            <div className="divide-y divide-gray-200">
                                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                    <form onSubmit={handleSubmit}>
                                        {/* Email Input */}
                                        <div className="relative mb-8">
                                            <input
                                                autoComplete='off'
                                                autoCorrect='off'

                                                onChange={handleChange}
                                                value={email}
                                                name="email"
                                                type="text"
                                                className="peer text-[16px] h-10 w-full border-b-2 border-gray-500 bg-gray-950 text-gray-100 focus:outline-none focus:border-orange-600"
                                                placeholder={t("Your Email")}
                                            />
                                        </div>
                                        {/* Subject Input */}
                                        <div className="relative mb-8">
                                            <input
                                                autoComplete='off'
                                                autoCorrect='off'

                                                onChange={handleChange}
                                                value={subject}
                                                name="subject"
                                                type="text"
                                                className="peer text-[16px] h-10 w-full border-b-2 border-gray-500 bg-gray-950 text-gray-100 focus:outline-none focus:border-orange-600"
                                                placeholder={t("Subject")}
                                            />
                                        </div>
                                        {/* Message Textarea */}
                                        <div className="relative mb-8">
                                            <input
                                                autoComplete='off'
                                                autoCorrect='off'
                                                onChange={handleChange}
                                                value={message}
                                                name="message"
                                                className="peer text-[16px] h-10 w-full border-b-2 border-gray-500 bg-gray-950 text-gray-100 focus:outline-none focus:border-orange-600"
                                                placeholder={t("Message")}
                                            ></input>
                                        </div>
                                        {/* Submit Button */}
                                        <div className="relative flex justify-center " >
                                            <button
                                                type="submit"
                                                className="bg-orange-600 text-[16px] mt-[10px] font-bold text-center text-white rounded-md px-4 py-1"
                                            >
                                                {t('Submit')}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
};

export default Support;
