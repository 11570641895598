import React, { useEffect, useState } from 'react'
import { GLOBALTYPES } from '../redux/actions/GlobalTypes';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { save_analyze_data } from '../redux/actions/user/FeaturesAction';
import { useTranslation } from 'react-i18next'
import { blockName, customFeatureCount, deleteName, showName } from '../common/features';
import { Button, ButtonToolbar, OverlayTrigger, Popover } from 'react-bootstrap';
import { BsQuestionLg } from 'react-icons/bs';
import { blockUserQ, deleteCommentsQ, objectQ, showCommentsQ } from '../common/questionMark';
import { planName } from '../common/plan';
import { getUserProfile } from '../redux/actions/user/UserDetails';



function Features({ header }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const token = localStorage.getItem('eonToken')
    const isYoutubeSignIn = localStorage.getItem('isYoutubeSignIn')
    const channelId = localStorage.getItem('selectedChannelId')
    const activePlan = useSelector(state => state.subscription?.subscribed_plan?.planType)
    const userDetails = useSelector(state => state.user?.user)
    const [showAll, setShowAll] = useState(false); // State to track button display
    const [showDeleteAll, setDeleteShowAll] = useState(false); // State to track button display
    const [showBlockAll, setBlockShowAll] = useState(false); // State to track button display
    const maxButtonsToShow = 3;

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };
    const toggleDeleteAll = () => {
        setDeleteShowAll(!showDeleteAll);
    };
    const toggleBlockAll = () => {
        setBlockShowAll(!showBlockAll);
    };

    useEffect(() => {
        if (token) {

            dispatch(getUserProfile(token))
        }
    }, [dispatch, token])


    const [showSelectedButtons, setShowSelectedButtons] = useState({});
    const [showSelectedStates, setShowSelectedStates] = useState({});

    const [deleteSelectedButtons, setDeleteSelectedButtons] = useState({});
    const [deleteSelectedStates, setDeleteSelectedStates] = useState({});

    const [blockSelectedButtons, setBlockSelectedButtons] = useState({});
    const [blockSelectedStates, setBlockSelectedStates] = useState({});


    const [showInputValue, setShowInputValue] = useState('');
    const [deleteInputValue, setDeleteInputValue] = useState('');
    const [blockInputValue, setBlockInputValue] = useState('');

    const handleShowInputChange = (event) => {
        setShowInputValue(event.target.value);
    };
    const handleDeleteInputChange = (event) => {
        setDeleteInputValue(event.target.value);
    };
    const handleBlockInputChange = (event) => {
        setBlockInputValue(event.target.value);
    };


    const toggleShowButton = (button) => {
        setShowSelectedButtons(prevState => ({
            ...prevState,
            [button]: !prevState[button]
        }));

    };

    const toggleDeleteButton = (button) => {
        setDeleteSelectedButtons(prevState => ({
            ...prevState,
            [button]: !prevState[button]
        }));
    }
    const toggleBlockButton = (button) => {
        setBlockSelectedButtons(prevState => ({
            ...prevState,
            [button]: !prevState[button]
        }));
    }

    function checkUnauthorizedSelections(selectedStates, plan, featureType) {
        const planFeatures = {
            Basic: { show: [], delete: deleteName, block: [] },
            Influencer: { show: [], delete: deleteName, block: blockName },
            VIP: { show: showName, delete: deleteName, block: blockName },
            Celebrity: { show: showName, delete: deleteName, block: blockName },
            // Add the mapping for the 'celebrity' plan if needed
        };

        const allowedFeatures = planFeatures[plan][featureType];
        return Object.keys(selectedStates).some(key => selectedStates[key] && !allowedFeatures.includes(key));
    }

    const saveAllSelectedButtons = () => {
        showName.forEach(showbtn => {
            showSelectedStates[showbtn] = !!showSelectedButtons[showbtn]
        })
        deleteName.forEach(deletebtn => {
            deleteSelectedStates[deletebtn] = !!deleteSelectedButtons[deletebtn]
        })
        blockName.forEach(blockbtn => {
            blockSelectedStates[blockbtn] = !!blockSelectedButtons[blockbtn]
        })

        const allShowButtonsUnselected =
            Object.values(showSelectedStates)
                .filter(value => typeof value === 'boolean')
                .every(value => value === false);

        const allDeleteButtonsUnselected = Object.values(deleteSelectedStates)
            .filter(value => typeof value === 'boolean')
            .every(value => value === false);

        const allBlockButtonsUnselected = Object.values(blockSelectedStates)
            .filter(value => typeof value === 'boolean')
            .every(value => value === false);

        if (allShowButtonsUnselected && allDeleteButtonsUnselected && allBlockButtonsUnselected && showInputValue.length < 1 && deleteInputValue.length < 1 && blockInputValue.length < 1) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: 'Please Select The Option'
                }
            })
            setShowSelectedButtons({})
            setDeleteSelectedButtons({})
            setBlockSelectedButtons({})
        }
        else {
            if (isYoutubeSignIn) {

                if (activePlan) {



                    const plan = activePlan

                    // Attempt to reset states based on plan and check for unauthorized selections
                    const unauthorizedShowSelections = checkUnauthorizedSelections(showSelectedStates, plan, 'show');
                    // const unauthorizedDeleteSelections = checkUnauthorizedSelections(deleteSelectedStates, plan, 'delete');
                    const unauthorizedBlockSelections = checkUnauthorizedSelections(blockSelectedStates, plan, 'block');

                    // If any unauthorized selections are found, dispatch a toaster error and do not proceed
                    if (unauthorizedShowSelections || unauthorizedBlockSelections) {
                        let errorMessages
                        if (unauthorizedShowSelections) {
                            errorMessages = 'Current Subscription Not Includes Show Features'
                            dispatch({
                                type: GLOBALTYPES.ALERT,
                                payload: {
                                    error: errorMessages
                                }
                            })

                            return;
                        }
                        if (unauthorizedBlockSelections) {
                            errorMessages = 'Current Subscription Not Includes BLock Features'
                            dispatch({
                                type: GLOBALTYPES.ALERT,
                                payload: {
                                    error: errorMessages
                                }
                            })

                            return;
                        }

                    }

                    if (showInputValue || deleteInputValue || blockInputValue) {
                        if (plan !== planName['four']) {
                            let errorMessages = 'Current Subscription Not Includes Custom Features'
                            dispatch({
                                type: GLOBALTYPES.ALERT,
                                payload: {
                                    error: errorMessages
                                }
                            })

                            return;
                        }


                        if (plan === planName['four'] && userDetails?.customFeatureCount > customFeatureCount - 1) {
                            let errorMessages = 'Your monthly limit has been reached for custom selection.'
                            dispatch({
                                type: GLOBALTYPES.ALERT,
                                payload: {
                                    error: errorMessages
                                }
                            })

                            return;
                        }

                    }

                    setShowSelectedStates(showSelectedStates)
                    setDeleteSelectedStates(deleteSelectedStates)
                    setBlockSelectedStates(blockSelectedStates)
                    if (showInputValue.length > 0) {
                        showSelectedStates.custom = showInputValue
                    }
                    if (deleteInputValue.length > 0) {
                        deleteSelectedStates.custom = deleteInputValue
                    }
                    if (blockInputValue.length > 0) {
                        blockSelectedStates.custom = blockInputValue
                    }

                    dispatch(save_analyze_data({ showSelectedStates, deleteSelectedStates, blockSelectedStates, channelId, token }))

                    setShowSelectedButtons({})
                    setDeleteSelectedButtons({})
                    setBlockSelectedButtons({})
                    showSelectedStates.custom = ""
                    deleteSelectedStates.custom = ""
                    blockSelectedStates.custom = ""
                    setShowInputValue("")
                    setDeleteInputValue("")
                    setBlockInputValue("")
                }
                else {
                    dispatch({
                        type: GLOBALTYPES.ALERT,
                        payload: {
                            error: 'Please, Subscribe Subscription'
                        }
                    })
                }
            }

            else {
                dispatch({
                    type: GLOBALTYPES.ALERT,
                    payload: {
                        error: 'First, Sign In with youtube '
                    }
                })
            }
        }

    }

    // const handleViewHistory = () => {
    //     // localStorage.setItem("featureName", 'Show Comments')
    //     navigate('/history')
    // }

    const handleViewHistoryShow = () => {
        localStorage.setItem("featureName", 'Show Comments')
        navigate('/history')
    }
    const handleViewHistoryDelete = () => {
        localStorage.setItem("featureName", 'Deleted Comments')
        navigate('/history')
    }
    const handleViewHistoryBlock = () => {
        localStorage.setItem("featureName", 'Blocked User')
        navigate('/history')
    }



    const showUserbuttonClass = showInputValue.length > 0 ? 'bg-orange-500 text-white' : 'bg-gray-300';
    const deleteUserbuttonClass = deleteInputValue.length > 0 ? 'bg-orange-500 text-white' : 'bg-gray-300';
    const blockUserbuttonClass = blockInputValue.length > 0 ? 'bg-orange-500 text-white' : 'bg-gray-200';

    const inputTagBorderShow = showInputValue.length > 0 ? 'border-white' : 'border-black';
    const inputTagBorderDelete = deleteInputValue.length > 0 ? 'border-white' : 'border-black';
    const inputTagBorderBlock = blockInputValue.length > 0 ? 'border-white' : "border-black ";
    return (
        <>
            <div className="container max-w-[1450px] "
                style={{ marginTop: "50px" }}
            >
                <div className="row bg-gray-900 rounded-[10px] pt-[50px] pb-[30px] px-[20px] flex items-center justify-center flex-col">
                    <div className="col-lg-8 ">
                        <div className="section-heading flex items-center justify-center" >
                            <h4>Step 3 : {t("Your Current Plan")} : <em> {activePlan ? activePlan : t('None')}</em></h4>
                        </div>
                    </div>
                    <div className=" mx-auto mt-[40px] mb-[20px]">
                        <div className="space-y-4">
                            <div className="grid sm:grid-cols-3 grid-cols-1 gap-4">
                                <div className='flex flex-col gap-[14px]'>
                                    <div type="button" className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-[5px] text-sm px-5 py-[13px] text-center  mb-2 mx-auto">Show Comments</div>
                                    {/* <button className='bg-gray-700 shadow-sm mb-[10px] py-[15px] font-semibold max-w-[250px] mx-auto rounded-[6px] px-[20px] text-white '>
                                        Show Comments
                                    </button> */}
                                    {showName.map((showBtn, index) => {
                                        const isVisible = index < maxButtonsToShow || showAll;
                                        return (
                                            <div
                                                key={index}
                                                className={`transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`}
                                                style={{ display: isVisible ? 'block' : 'none' }} // You can also toggle display property if you want to remove the element from the flow
                                            >
                                                <ShowButton
                                                    key={index}
                                                    text={t(showBtn)}
                                                    textQ={showCommentsQ[index]}
                                                    isSelected={showSelectedButtons[showBtn]}
                                                    onClick={() => toggleShowButton(showBtn)}
                                                />
                                            </div>
                                        );
                                    })}


                                    <div className='relative'>

                                        <button className={`${showUserbuttonClass} w-[93%] font-bold py-[13px] rounded`}>{t("Show")}
                                            <input
                                                placeholder="" maxLength={100}
                                                className={`mx-[8px] ${inputTagBorderShow} border-b-[2px] bg-transparent  font-sans text-[16px] font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200  focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50`}
                                                value={showInputValue}
                                                onChange={(event) => {
                                                    const inputValue = event.target.value;
                                                    if (inputValue.split(' ').length <= 100) {
                                                        handleShowInputChange(event);
                                                    }
                                                }}
                                            />
                                            {t('Comments')}
                                        </button>

                                        <ButtonToolbar>
                                            <OverlayTrigger
                                                trigger={['hover', 'focus']}
                                                placement="bottom"
                                                overlay={
                                                    <Popover className='p-[10px] text-black' id={`popover-trigger-hover-focus`} title={objectQ['customShowQ']}>
                                                        {objectQ['customShowQ']}
                                                    </Popover>
                                                }
                                            >
                                                <Button className='p-0 border-0 absolute top-0 bottom-0 right-0' style={{ background: "none", color: "none" }}>
                                                    <div className='rounded-full p-[1.5px] mt-[2px] bg-transparent  border-1 border-solid border-white '>

                                                        <BsQuestionLg className='h-[10px] w-[10px] text-white flex-shrink-0 ' id='popover-trigger-hover-focus' />

                                                    </div>
                                                </Button>
                                            </OverlayTrigger>
                                        </ButtonToolbar>
                                    </div>
                                    <button onClick={toggleShowAll} className="transition-opacity duration-500 w-[50%] mx-auto  mr-[30px] font-bold py-[13px] rounded bg-gray-300 text-black flex items-center justify-center">
                                        {showAll ? 'See Less' : 'See More'}
                                        <svg
                                            className="ml-1 w-5 h-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            {!showAll ? (
                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                            ) : (
                                                <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414 6.707 12.707a1 1 0 11-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
                                            )}
                                        </svg>
                                    </button>

                                    <button
                                        className={`mt-[14px] font-bold w-[93%] py-[13px] px-4 rounded bg-gray-700 text-white `}
                                        onClick={handleViewHistoryShow}
                                    >
                                        {t('View History')}
                                    </button>
                                </div>
                                <div className='flex flex-col gap-[14px]'>
                                    <div type="button" className="text-white bg-gradient-to-r from-red-500 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-[5px] text-sm px-5 py-[13px] text-center mr-2 mb-2 mx-auto">Delete Comments</div>
                                    {/* <button className='bg-gray-700 mb-[10px] py-[15px] font-semibold max-w-[250px] mx-auto rounded-[6px] px-[20px] text-white  '>
                                        Delete Comments
                                    </button> */}
                                    {
                                        deleteName.map((deleteBtn, index) => {
                                            const isVisible = index < maxButtonsToShow || showDeleteAll;
                                            return (
                                                <div
                                                    key={index}
                                                    className={`transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`}
                                                    style={{ display: isVisible ? 'block' : 'none' }} // You can also toggle display property if you want to remove the element from the flow
                                                >
                                                    <DeleteButton
                                                        key={index}
                                                        text={t(deleteBtn)}
                                                        textQ={deleteCommentsQ[index]}
                                                        isSelected={deleteSelectedButtons[deleteBtn]}
                                                        onClick={() => toggleDeleteButton(deleteBtn)}
                                                    />
                                                </div>
                                            );

                                        })
                                    }
                                    <div className='relative'>
                                        <button className={`${deleteUserbuttonClass} w-[93%] font-bold py-[13px]  rounded`}>{t('Delete')}
                                            <input
                                                placeholder="" maxLength={100}
                                                className={`mx-[8px] ${inputTagBorderDelete} border-b-[2px] bg-transparent  font-sans text-[16px] font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200  focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50`}
                                                value={deleteInputValue}
                                                onChange={handleDeleteInputChange}
                                            />
                                            {t('Comments')}
                                        </button>

                                        <ButtonToolbar>
                                            <OverlayTrigger
                                                trigger={['hover', 'focus']}
                                                placement="bottom"
                                                overlay={
                                                    <Popover className='p-[10px] text-black' id={`popover-trigger-hover-focus`} title={objectQ['customDeleteQ']}>
                                                        {objectQ['customDeleteQ']}
                                                    </Popover>
                                                }
                                            >
                                                <Button className='p-0 border-0 absolute top-0 bottom-0 right-0' style={{ background: "none", color: "none" }}>
                                                    <div className='rounded-full p-[1.5px] mt-[2px] bg-transparent  border-1 border-solid border-white '>

                                                        <BsQuestionLg className='h-[10px] w-[10px] text-white flex-shrink-0 ' id='popover-trigger-hover-focus' />

                                                    </div>
                                                </Button>
                                            </OverlayTrigger>
                                        </ButtonToolbar>
                                    </div>
                                    <button onClick={toggleDeleteAll} className="transition-opacity duration-500 w-[50%] mx-auto  mr-[30px] font-bold py-[13px] rounded bg-gray-300 text-black flex items-center justify-center">
                                        {showDeleteAll ? 'See Less' : 'See More'}
                                        <svg
                                            className="ml-1 w-5 h-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            {!showDeleteAll ? (
                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                            ) : (
                                                <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414 6.707 12.707a1 1 0 11-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
                                            )}
                                        </svg>
                                    </button>
                                    <button
                                        className={`mt-[14px] w-[93%] font-bold py-[13px] px-4 rounded bg-gray-700 text-white `}
                                        onClick={handleViewHistoryDelete}
                                    >
                                        {t('View History')}
                                    </button>

                                </div>
                                <div className='flex flex-col gap-[14px]'>
                                    <div type="button" className="text-gray-900 bg-gradient-to-r from-yellow-400 via-yellow-400 to-yellow-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 shadow-lg shadow-lime-500/50 dark:shadow-lg dark:shadow-lime-800/80 font-medium rounded-[5px] text-sm px-5 py-[13px] text-center mr-2 mb-2 mx-auto">Block Users</div>
                                    {/* <button className='bg-gray-700 mb-[10px] py-[15px] font-semibold max-w-[250px] mx-auto rounded-[6px] px-[20px] text-white  '>
                                        Block Users
                                    </button> */}
                                    {
                                        blockName.map((blockBtn, index) => {
                                            const isVisible = index < maxButtonsToShow || showBlockAll;
                                            return (
                                                <div
                                                    key={index}
                                                    className={`transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`}
                                                    style={{ display: isVisible ? 'block' : 'none' }} // You can also toggle display property if you want to remove the element from the flow
                                                >
                                                    <BlockButton
                                                        key={index}
                                                        text={t(blockBtn)}
                                                        textQ={blockUserQ[index]}
                                                        isSelected={blockSelectedButtons[blockBtn]}
                                                        onClick={() => toggleBlockButton(blockBtn)}
                                                    />
                                                </div>
                                            );
                                        })
                                    }


                                    <div className='relative'>

                                        <button className={`${blockUserbuttonClass} w-[93%]  font-bold py-[13px]  rounded`}>{t('Block users with')}
                                            <input
                                                placeholder="" maxLength={100}
                                                className={`mx-[8px] ${inputTagBorderBlock} border-b-[2px] bg-transparent  font-sans text-[16px] font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200  focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 shadow-sm`}
                                                value={blockInputValue}
                                                onChange={handleBlockInputChange}
                                            />
                                            {t('Comments')}
                                        </button>

                                        <ButtonToolbar>
                                            <OverlayTrigger
                                                trigger={['hover', 'focus']}
                                                placement="bottom"
                                                overlay={
                                                    <Popover className='p-[10px] text-black' id={`popover-trigger-hover-focus`} title={objectQ['customBolckQ']}>
                                                        {objectQ['customBolckQ']}
                                                    </Popover>
                                                }
                                            >
                                                <Button className='p-0 border-0 absolute top-0 bottom-0 right-0' style={{ background: "none", color: "none" }}>
                                                    <div className='rounded-full p-[1.5px] mt-[2px] bg-transparent  border-1 border-solid border-white  '>

                                                        <BsQuestionLg className='h-[10px] w-[10px] text-white flex-shrink-0 ' id='popover-trigger-hover-focus' />

                                                    </div>
                                                </Button>
                                            </OverlayTrigger>
                                        </ButtonToolbar>
                                    </div>

                                    <button onClick={toggleBlockAll} className="transition-opacity duration-500 w-[50%] mx-auto  mr-[30px] font-bold py-[13px] rounded bg-gray-300 text-black flex items-center justify-center">
                                        {showBlockAll ? 'See Less' : 'See More'}
                                        <svg
                                            className="ml-1 w-5 h-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            {!showBlockAll ? (
                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                            ) : (
                                                <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414 6.707 12.707a1 1 0 11-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
                                            )}
                                        </svg>
                                    </button>
                                    <button
                                        className={`mt-[14px] w-[93%] font-bold py-[13px] px-4 rounded bg-gray-700 text-white `}
                                        onClick={handleViewHistoryBlock}

                                    >
                                        {t('View History')}
                                    </button>

                                </div>
                            </div>
                            <div className='flex flex-col items-center'>

                                {/* <button
                                    className="middle w-[325px] none center  rounded-lg bg-gray-950 hover:bg-gray-600 py-[14px] px-6 font-sans text-[16px] font-bold  text-white shadow-md  transition-all hover:shadow-lg  focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                    data-ripple-light="true" style={{ marginTop: "10px" }} onClick={handleViewHistory}
                                >
                                    {t('View History')}
                                </button> */}
                                <button
                                    className="middle w-[325px] none center  rounded-lg bg-orange-600 hover:bg-orange-500 py-[14px] px-6 font-sans text-[17px] font-bold uppercase text-white shadow-md  transition-all hover:shadow-lg  focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                    data-ripple-light="true" style={{ marginTop: "10px" }} onClick={saveAllSelectedButtons}
                                >
                                    {t('save')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div></div>
        </>
    )
}

export default Features

const ShowButton = ({ text, onClick, isSelected, textQ }) => {

    return (
        <div className='relative '>
            <button
                className={`transition-opacity duration-500 w-[93%]  pr-[30px] font-bold py-[13px] rounded ${isSelected ? 'bg-orange-500 text-white' : 'bg-gray-300 text-black '}`} onClick={onClick}
            >
                {text}
            </button>
            <ButtonToolbar>
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={
                        <Popover className='p-[10px] text-black' id={`popover-trigger-hover-focus`} title={textQ}>
                            {textQ}
                        </Popover>
                    }
                >
                    <Button className='p-0 border-0 absolute top-0 bottom-0 right-0' style={{ background: "none", color: "none" }}>
                        <div className='rounded-full p-[1.5px] mt-[2px] bg-transparent  border-1 border-solid border-white'>

                            <BsQuestionLg className='h-[10px] w-[10px] text-white flex-shrink-0 ' id='popover-trigger-hover-focus' />

                        </div>
                    </Button>
                </OverlayTrigger>
            </ButtonToolbar>
        </div>


    );
};

const DeleteButton = ({ text, onClick, isSelected, textQ }) => {
    return (
        <div className='relative'>
            <button
                className={`w-[93%] pr-[30px] font-bold py-[13px]  rounded ${isSelected ? 'bg-orange-500 text-white' : 'bg-gray-300 text-black '}`} onClick={onClick}
            >
                <span>{text}</span>
            </button>

            <ButtonToolbar>
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={
                        <Popover className='p-[10px] text-black' id={`popover-trigger-hover-focus`} title={textQ}>
                            {textQ}
                        </Popover>
                    }
                >
                    <Button className='p-0 border-0 absolute top-0 bottom-0 right-0' style={{ background: "none", color: "none" }}>
                        <div className='rounded-full p-[1.5px] mt-[2px] bg-transparent  border-1 border-solid border-white  '>

                            <BsQuestionLg className='h-[10px] w-[10px] text-white flex-shrink-0 ' id='popover-trigger-hover-focus' />

                        </div>
                    </Button>
                </OverlayTrigger>
            </ButtonToolbar>
        </div>
    );
};

const BlockButton = ({ text, onClick, isSelected, textQ }) => {
    return (
        <div className='relative'>
            <button
                className={`w-[93%] pr-[30px] font-bold py-[13px] px-[3px] rounded ${isSelected ? 'bg-orange-500 text-white' : 'bg-gray-300 text-black '}`} onClick={onClick}
            >
                <span>{text}</span>
            </button>

            <ButtonToolbar>
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={
                        <Popover className='p-[10px] text-black' id={`popover-trigger-hover-focus`} title={textQ}>
                            {textQ}
                        </Popover>
                    }
                >
                    <Button className='p-0 border-0 absolute top-0 bottom-0 right-0' style={{ background: "none", color: "none" }}>
                        <div className='rounded-full p-[1.5px] mt-[2px] bg-transparent  border-1 border-solid border-white  '>

                            <BsQuestionLg className='h-[10px] w-[10px] text-white flex-shrink-0 ' id='popover-trigger-hover-focus' />

                        </div>
                    </Button>
                </OverlayTrigger>
            </ButtonToolbar>
        </div>


    );
};


